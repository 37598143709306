import React, {ChangeEvent, useState} from 'react';
import '../../styles/pages/login.scss';
import {SubmitButton, TextInput} from '../../components';
import useAuthStore from '../../store/useAuth';
import {useNavigate} from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [
        login
    ] = useAuthStore((state) => [
        state.login
    ]);


    const loginSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(email)
        login(email, password, navigate)
    };

    return (
        <div className='login'>
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} className='login-logo' alt='logo'/>
            <form action="" className='login-form' onSubmit={loginSubmit}>
                <div className='login-form-input'>
                    <TextInput
                        placeholder='Email'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <div className='login-form-input'>
                    <TextInput
                        placeholder='Password'
                        type='password'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        value={password}
                    />
                </div>
                <div className='login-form-btn'>
                    <SubmitButton
                        title='Login'
                    />
                </div>
            </form>
        </div>
    );
};

export default Login;