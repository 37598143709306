import {PageContent} from '../../components';
import {useSeriesDelete} from '../../hooks/Series/useSeriesMutation';
import {useGetSeries} from '../../hooks/Series/useSeriesQueries';

const columns: string[] = [
    'ID', 'Name'
]

const Series = () => {
    const {data, isSuccess, refetch} = useGetSeries(0, 20);
    const {mutate: deleteSeries} = useSeriesDelete(refetch);

    return <PageContent
        title='Series'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/series/add/'
        deleteItem={deleteSeries}
    />;
};

export default Series;