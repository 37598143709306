import '../../../styles/components/UI/UploadImage.scss';
import React, {useEffect, useRef} from 'react';
import {IoCloseSharp} from "react-icons/io5";
import {MdAdd} from "react-icons/md";
import {TiMinus} from "react-icons/ti";
import InputFile from './InputFile';
import {LuImagePlus} from 'react-icons/lu';

interface UploadImageProps {
    preview: string | undefined;
    isMulti: boolean;
    onImageSelect?: (base64: string | undefined) => void;
    index?: number;
    onImageMultiSelect?: (index: number, base64: string | undefined) => void;
    addImageInput?: (e: any) => void;
    removeImageInput?: (index: number) => void;
}

const UploadImage: React.FC<UploadImageProps> = ({
                                                     preview,
                                                     onImageSelect,
                                                     isMulti,
                                                     index,
                                                     onImageMultiSelect,
                                                     addImageInput,
                                                     removeImageInput
                                                 }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (preview === undefined) {
            resetFileInput();
        }
    }, [preview]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                if (index && onImageMultiSelect) {
                    onImageMultiSelect(index, base64String)
                } else {

                    if (onImageSelect) {
                        onImageSelect(base64String);
                    }
                }
            };
            reader.readAsDataURL(file);
        } else {
            if (index && onImageMultiSelect) {
                onImageMultiSelect(index, undefined)
            } else {
                if (onImageSelect) {
                    onImageSelect(undefined);
                }
            }
        }
    };

    const clear = () => {
        if (index && onImageMultiSelect) {
            onImageMultiSelect(index, undefined)
        } else {
            if (onImageSelect) {
                onImageSelect(undefined);
            }
        }
        resetFileInput();
    };

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };


    const removeImageBtn = (e: any) => {
        if (removeImageInput && index) {
            removeImageInput(index);
        }
    }

    return (
        <div className="upload-image-container">
            {
                preview &&
                <div className="preview-image">
                    <IoCloseSharp onClick={clear}/>
                    <img src={preview} alt="Preview"/>
                </div>
            }
            {
                preview === undefined
                    ?
                    <div className="upload-image-input">
                        <InputFile
                            icon={<LuImagePlus/>}
                            accept='image/*'
                            onChange={handleFileChange}
                        />
                        {
                            isMulti
                                ?
                                <div className="upload-image-input-btn">
                                    <MdAdd onClick={addImageInput}/>
                                    <TiMinus onClick={removeImageBtn}/>
                                </div>

                                :
                                <></>
                        }
                    </div>
                    :
                    <></>
            }


        </div>
    );
};

export default UploadImage;
