import React, {ChangeEvent, useEffect, useState} from 'react';
import '../../../styles/components/UI/MultiImageUpload.scss';
import uuid from 'react-uuid';
import {ImageListProduct} from '../../../pages/Product/ProductAdd';
import {LuImagePlus} from "react-icons/lu";
import {IoCloseSharp} from 'react-icons/io5';
import InputFile from './InputFile';
import {CheckBox, TextInput} from "../../index";

interface MultiImageUploadProps {
    imageLimit: number;
    images: ImageListProduct[];
    uploadImageEvent: (items: ImageListProduct[]) => void;
}


const MultiImageUpload: React.FC<MultiImageUploadProps> = ({images, uploadImageEvent, imageLimit}) => {
    const [imageArr, setImageArr] = useState<ImageListProduct[]>(images);


    useEffect(() => {
        if (imageLimit === 1) {
            uploadImageEvent(imageArr.filter((_image, index) => index !== imageArr.length));
        } else {
            uploadImageEvent(imageArr.filter((_image, index) => index !== imageArr.length));
        }
        handleAddImage();
    }, [imageArr]);

    const handleAddImage = () => {
        if (imageArr[imageArr.length - 1].value && imageArr.length < imageLimit) {
            setImageArr([...imageArr, {
                index: uuid(),
                value: '',
                extension: '',
                imageOrder: 0,
                isActive: false
            }]);
        }
    };


    const handleRemoveImage = (idToRemove: string) => {
        setImageArr(imageArr.filter((image) => image.index !== idToRemove));
    };


    const handleFileChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {

        const file = event.target.files?.[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const tempImage = reader.result as string;
            setImageArr(
                imageArr.map((image) =>
                    image.index === id ? {
                        ...image,
                        value: tempImage,
                        extension: tempImage.split('data:image/')[1].split(';base64,')[0],
                        imageOrder: image.imageOrder,
                        isActive: image.isActive
                    } : image
                )
            );
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };


    const clear = (id: string) => {
        setImageArr(
            imageArr.map((image) =>
                image.index === id ? {...image, value: '', extension: '', imageOrder: 0} : image
            )
        );
        handleRemoveImage(id);
    };

    const setImageOrder = (index: string, e: ChangeEvent<HTMLInputElement>) => {
        setImageArr(
            imageArr.map((image) =>
                image.index === index ? {...image, imageOrder: Number(e.target.value)} : image
            )
        );
    }

    const setIsActive = (index: string, e: ChangeEvent<HTMLInputElement>) => {
        setImageArr(
            imageArr.map((image) =>
                image.index === index ? {...image, isActive: e.target.checked} : image
            )
        );
    };


    return (
        <div className='multi-image-select'>
            <div className='multi-image-select-header'>
                <p>Images</p>
            </div>
            <div className='multi-image-select-items'>
                {imageArr.map((image) => (

                    <div className='multi-image-select-container' key={image.index}>

                        <div key={image.index} className='multi-image-select-item'>

                            {
                                image.value
                                    ?
                                    <></>
                                    :
                                    <InputFile
                                        icon={<LuImagePlus/>}
                                        accept='image/*'
                                        onChange={
                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleFileChange(image.index, e)

                                            }}
                                    />
                            }

                            {image.value && (
                                <div className='image-container'>
                                    <IoCloseSharp onClick={() => clear(image.index)}/>
                                    <img src={image.value} alt="Preview"/>
                                </div>

                            )}

                        </div>
                        <div className='image-form'>
                            <CheckBox
                                disabled={!image.value}
                                label='Aktif'
                                name='isActive'
                                value='isActive'
                                checked={image.isActive}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setIsActive(image.index, e)}
                            />

                            <TextInput
                                disabled={!image.value}
                                placeholder='Resim sırası'
                                name='imageOrder'
                                type='number'
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setImageOrder(image.index, e)}
                                value={image.imageOrder.toString()}
                            />
                        </div>


                    </div>
                ))}
            </div>
        </div>

    );
};

export default MultiImageUpload;
