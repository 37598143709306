import {client} from "../api";
import {BookTypeBody, BookType, BookTypes, ResponseError, ResponseSuccess} from "../models";


class BookTypeService {

    async getBookTypes(page: number, pageSize: number): Promise<ResponseSuccess<BookTypes> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookTypes> | ResponseError>(`/api/v1/admin/book-types/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBookTypeByID(id: number): Promise<ResponseSuccess<BookType> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookType> | ResponseError>(`/api/v1/admin/book-types/${id}`)).data;
    }

    async createBookType(body: BookTypeBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-types`, body)).data;
    }

    async updateBookType(body: BookTypeBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-types`, body)).data;
    }

    async deleteBookType(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-types/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BookTypeService();