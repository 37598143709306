import {useQuery} from "@tanstack/react-query"
import {getCategoriesExclude, getCategoriesSRV} from "../../service"
import {Categories, CategoryContent, ResponseSuccess} from "../../models";


const useCategoriesQuery = (page: number, pageSize: number) => {

    return useQuery<ResponseSuccess<Categories>>({
        queryFn: () => getCategoriesSRV(page, pageSize),
        queryKey: ['categories']
    })
}

const useCategoriesExcludeQuery = (id: number) => {
    return useQuery<ResponseSuccess<CategoryContent[]>>({
        queryFn: () => getCategoriesExclude(id),
        queryKey: ['categories exclude']
    })
}

export {
    useCategoriesQuery,
    useCategoriesExcludeQuery
};