import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {BookSegments, BookSegment, ResponseSuccess} from "../../models";
import {bookSegmentService} from "../../service";


const useGetBookSegments = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<BookSegments>>({
        queryFn: () => bookSegmentService.getBookSegments(page, pageSize),
        queryKey: ['book-segments'],
        placeholderData: keepPreviousData
    })
}


const useGetBookSegment = (id: number) => {
    return useQuery<ResponseSuccess<BookSegment>>({
        queryFn: () => bookSegmentService.getBookSegmentByID(id),
        queryKey: ['book-segment', id]
    })
}


export {
    useGetBookSegments,
    useGetBookSegment
};