import {client} from "../api";
import {BookSegmentBody, BookSegment, BookSegments, ResponseError, ResponseSuccess} from "../models";


class BookSegmentService {

    async getBookSegments(page: number, pageSize: number): Promise<ResponseSuccess<BookSegments> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookSegments> | ResponseError>(`/api/v1/admin/book-segments/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBookSegmentByID(id: number): Promise<ResponseSuccess<BookSegment> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookSegment> | ResponseError>(`/api/v1/admin/book-segments/${id}`)).data;
    }

    async createBookSegment(body: BookSegmentBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-segments`, body)).data;
    }

    async updateBookSegment(body: BookSegmentBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-segments`, body)).data;
    }

    async deleteBookSegment(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-segments/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BookSegmentService();