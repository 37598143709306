import {useMutation} from "@tanstack/react-query";
import {CreatePropertyBody, CreatePropertyValue} from "../../models";
import {propertyService} from "../../service";
import toast from "react-hot-toast";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateProperty = (refetch: any) => {

    return useMutation({
        mutationKey: ['create property'],
        mutationFn: (body: CreatePropertyBody) => propertyService.createProperty(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateProperty = (refetch: any) => {

    return useMutation({
        mutationKey: ['update property'],
        mutationFn: (body: CreatePropertyBody) => propertyService.updateProperty(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteProperty = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete property'],
        mutationFn: (id: number) => propertyService.deleteProperty(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useCreatePropertyValue = (refetch: any) => {

    return useMutation({
        mutationKey: ['update property value'],
        mutationFn: (body: CreatePropertyValue) => propertyService.createPropertyValue(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useUpdatePropertyValue = (refetch: any) => {

    return useMutation({
        mutationKey: ['update property value'],
        mutationFn: (body: CreatePropertyValue) => propertyService.updatePropertyValue(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useDeletePropertyValue = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete property value'],
        mutationFn: (id: number) => propertyService.deletePropertyValue(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateProperty,
    useUpdateProperty,
    useDeleteProperty,
    useCreatePropertyValue,
    useUpdatePropertyValue,
    useDeletePropertyValue
}