import {client} from "../api";
import {
    BookContentLanguageBody,
    BookContentLanguage,
    BookContentLanguages,
    ResponseError,
    ResponseSuccess
} from "../models";


class BookContentLanguageService {

    async getBookContentLanguages(page: number, pageSize: number): Promise<ResponseSuccess<BookContentLanguages> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookContentLanguages> | ResponseError>(`/api/v1/admin/book-content-languages/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBookContentLanguageByID(id: number): Promise<ResponseSuccess<BookContentLanguage> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookContentLanguage> | ResponseError>(`/api/v1/admin/book-content-languages/${id}`)).data;
    }

    async createBookContentLanguage(body: BookContentLanguageBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-content-languages`, body)).data;
    }

    async updateBookContentLanguage(body: BookContentLanguageBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-content-languages`, body)).data;
    }

    async deleteBookContentLanguage(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-content-languages/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BookContentLanguageService();