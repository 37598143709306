import React from 'react';
import {useParams} from 'react-router-dom';
import {useGetProduct} from '../../hooks';

const ProductEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetProduct(Number(id));

    return (
        <div>

        </div>
    );


};

export default ProductEdit;