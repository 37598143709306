import '../styles/components/pageContent.scss';
import React, {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import AddButton from './UI/Button/AddButton';
import Table from './UI/Table/Table';
import {
    AuthorContent,
    BookTypeContent,
    BookSegmentContent,
    BookPaperQualityContent,
    BookCoverTypeContent,
    BookContentLanguageContent,
    BookPublishingBrandContent,
    BrandContent,
    CategoryContent,
    MenuContent,
    ProductContent,
    ProductTypeContent,
    PropertyContent,
    PublisherHouseContent,
    SeriesContent
} from '../models';

interface Props {
    title: string;
    columns: string[];
    items: (
        CategoryContent |
        MenuContent |
        PublisherHouseContent |
        SeriesContent |
        AuthorContent |
        ProductContent |
        PropertyContent |
        ProductTypeContent |
        BrandContent |
        BookTypeContent |
        BookSegmentContent |
        BookPaperQualityContent |
        BookCoverTypeContent |
        BookContentLanguageContent |
        BookPublishingBrandContent
        )[];
    addLink: string;
    deleteItem: (id: number) => void;
    totalPage?: number;
    setVisible?: (id: number, isVisible: boolean) => void;
}


const PageContent: FC<Props> = ({title, columns, items, addLink, deleteItem, totalPage, setVisible}) => {
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        const value = localStorage.getItem('searchValue');
        if (value) setSearchValue(value)
    }, [])

    useEffect(() => {
        localStorage.setItem('searchValue', searchValue);
    }, [searchValue])


    return (
        <div className='page-content'>
            <div className='page-content-header'>
                <p>{title}</p>
                <Link to={addLink}>
                    <AddButton/>
                </Link>
            </div>
            <div>
                <Table
                    columns={columns}
                    items={items}
                    totalPage={totalPage}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    deleteItem={deleteItem}
                    setVisible={setVisible}
                />
            </div>
        </div>
    );
};

export default PageContent;