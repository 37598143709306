import {client} from "../api";
import {
    CreateProductTypeBody,
    ProductType,
    ProductTypes,
    ResponseError,
    ResponseSuccess,
    AvailableProperties,
    PropertyOfProductType
} from "../models";


class ProductTypeService {

    async getProductTypes(page: number, pageSize: number): Promise<ResponseSuccess<ProductTypes> | ResponseError | any> {
        return (await client.get<ResponseSuccess<ProductTypes> | ResponseError>(`/api/v1/admin/product-types?page=${page}&size=${pageSize}`)).data;
    }

    async getProductTypeByID(id: number): Promise<ResponseSuccess<ProductType> | ResponseError | any> {
        return (await client.get<ResponseSuccess<ProductType> | ResponseError>(`/api/v1/admin/product-types/${id}`)).data;
    }

    async createProductType(body: CreateProductTypeBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-types`, body)).data;
    }

    async updateProductType(body: CreateProductTypeBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-types`, body)).data;
    }

    async deleteProductType(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-types/${id}`)).data;
    }

    async getProductTypeAvailableProperty(id: number): Promise<ResponseSuccess<AvailableProperties[]> | ResponseError | any> {
        return (await client.get<ResponseSuccess<AvailableProperties[]> | ResponseError>(`/api/v1/admin/product-types/${id}/product-properties/available`)).data;
    }

    async getProductTypeProperty(id: number): Promise<ResponseSuccess<PropertyOfProductType[]> | ResponseError | any> {
        return (await client.get<ResponseSuccess<PropertyOfProductType[]> | ResponseError>(`/api/v1/admin/product-types/${id}/product-properties`)).data;
    }

    async setPropertyToProductType(productTypeId: number, propertyId: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.patch<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-types/${productTypeId}/product-properties/${propertyId}`)).data;
    }

    async deleteProductTypeProperty(productTypeId: number, propertyId: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-types/${productTypeId}/product-properties/${propertyId}`)).data;
    }

}


// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductTypeService();