import React from 'react';
import '../../../styles/components/UI/MultiSelect.scss';
import {AuthorContent, CategoryContent, SeriesContent} from '../../../models';
import {AvailableProperties} from '../../../models';
import CheckBox from '../Input/CheckBox';

interface MultiSelectProps {
    label: string;
    options: (AvailableProperties | AuthorContent | SeriesContent | CategoryContent)[];
    selectedOptions: number[];
    setSelectedOptions: (id: number[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({options, label, selectedOptions, setSelectedOptions}) => {

    const toggleOption = (optionValue: number) => {
        if (selectedOptions.includes(optionValue)) {
            setSelectedOptions(selectedOptions.filter(value => value !== optionValue));
        } else {
            setSelectedOptions([...selectedOptions, optionValue]);
        }
    };


    return (
        <div className="multi-select">
            <p className='multi-select-label'>{label}</p>
            <div className='multi-select-body'>
                {options.map(option => {
                        if ('productPropertyId' in option) {
                            return <CheckBox
                                key={option.productPropertyId}
                                label={option.name}
                                value={option.productPropertyId.toString()}
                                checked={selectedOptions.includes(option.productPropertyId)}
                                onChange={() => toggleOption(option.productPropertyId)}
                            />
                        } else if ('authorId' in option) {
                            return <CheckBox
                                key={option.authorId}
                                label={option.name + " " + option.surname}
                                value={option.authorId.toString()}
                                checked={selectedOptions.includes(option.authorId)}
                                onChange={() => toggleOption(option.authorId)}
                            />

                        } else if ('seriesId' in option) {
                            return <CheckBox
                                key={option.seriesId}
                                label={option.seriesLang.name}
                                value={option.seriesId.toString()}
                                checked={selectedOptions.includes(option.seriesId)}
                                onChange={() => toggleOption(option.seriesId)}
                            />
                        } else {
                            return <CheckBox
                                key={option.categoryId}
                                label={option.categoryLang.name}
                                value={option.categoryId.toString()}
                                checked={selectedOptions.includes(option.categoryId)}
                                onChange={() => toggleOption(option.categoryId)}
                            />
                        }
                    }
                )}
            </div>

        </div>
    );
};

export default MultiSelect;
