import {useQuery} from "@tanstack/react-query";
import {ResponseSuccess, Series, SeriesById} from "../../models";
import {getSeriesById, getSeriesSRV} from "../../service";

const useGetSeries = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<Series>>({
        queryFn: () => getSeriesSRV(page, pageSize),
        queryKey: ['series']
    })
}


const useGetSeriesById = (id: number) => {
    return useQuery<ResponseSuccess<SeriesById>>({
        queryFn: () => getSeriesById(id),
        queryKey: ['series by id', id]
    })
}

export {
    useGetSeries,
    useGetSeriesById
};