import {useQuery} from "@tanstack/react-query";
import {ResponseSuccess} from "../../models";
import {getPublisherHouse, getPublisherHousesSRV} from "../../service";
import {PublisherHouse, PublisherHouseById} from "../../models/publisherHouse.model";

const useGetPublisherHouses = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<PublisherHouse>>({
        queryFn: () => getPublisherHousesSRV(page, pageSize),
        queryKey: ['publisher houses']
    })
}


const useGetPublisherHouse = (id: number) => {
    return useQuery<ResponseSuccess<PublisherHouseById>>({
        queryFn: () => getPublisherHouse(id),
        queryKey: ['publisher house', id]
    })
}

export {
    useGetPublisherHouses,
    useGetPublisherHouse
};