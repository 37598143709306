import '../../styles/pages/author.scss';
import React, {FC, useEffect, useState} from 'react';
import {useForm} from "react-hook-form"
import {SubmitButton, TextArea, TextInput, UploadImage} from '../../components';
import {Author, AuthorBody, CreateImage} from '../../models';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {useAuthorCreate, useAuthorUpdate, useGetAuthors} from '../../hooks';
import uuid from 'react-uuid';

interface Input {
    name: string
    surname: string
    biography: string
}

interface InputObj {
    tr: Input,
    ru: Input,
    en: Input,
}


interface Props {
    author?: Author;
    isEdit: boolean;
}


export interface ImageListProduct {
    index: string;
    value: any
    extension: string | undefined
}


const UUID = uuid();

const EditAddAuthor: FC<Props> = ({isEdit, author}) => {
    const {data, isSuccess, refetch} = useGetAuthors(0, 20);
    const {mutate: create} = useAuthorCreate(refetch);
    const {mutate: update} = useAuthorUpdate(refetch);
    const {
        register,
        handleSubmit: submitEvent,
        watch,
        formState: {errors},
    } = useForm<InputObj>();

    //   const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data)

    const [formDataTR, setFormDataTR] = useState<Input>({
        name: '',
        surname: '',
        biography: ''
    });

    const [formDataRU, setFormDataRU] = useState<Input>({
        name: '',
        surname: '',
        biography: ''
    });

    const [formDataEN, setFormDataEN] = useState<Input>({
        name: '',
        surname: '',
        biography: ''
    });

    const [image, setImage] = useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };


    useEffect(() => {
        if (isEdit) {
            if (author) {
                if (author.image) {
                    setImage(`https://berlin-dev.knijka.com/api/v1/guest/authors/images/${author.image.imageCode}`);
                }

                for (let i = 0; i < author.authorLangList.length; i++) {
                    if (author.authorLangList[i].langCode === 'TR') {
                        setFormDataTR((prevData) => ({
                            ...prevData,
                            name: author.authorLangList[i].name,
                            surname: author.authorLangList[i].surname,
                            biography: author.authorLangList[i].biography,
                        }));
                    } else if (author.authorLangList[i].langCode === 'RU') {
                        setFormDataRU((prevData) => ({
                            ...prevData,
                            name: author.authorLangList[i].name,
                            surname: author.authorLangList[i].surname,
                            biography: author.authorLangList[i].biography,
                        }));
                    } else if (author.authorLangList[i].langCode === 'EN') {
                        setFormDataEN((prevData) => ({
                            ...prevData,
                            name: author.authorLangList[i].name,
                            surname: author.authorLangList[i].surname,
                            biography: author.authorLangList[i].biography,
                        }));
                    }
                }
            }
        }

    }, [author]);


    const handleChangeTR = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormDataTR((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeRU = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormDataRU((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleChangeEN = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormDataEN((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: AuthorBody = {
            authorLangList: [
                {
                    name: formDataTR.name,
                    surname: formDataTR.surname,
                    biography: formDataTR.biography,
                    langCode: 'TR'
                },
                {
                    name: formDataRU.name,
                    surname: formDataRU.surname,
                    biography: formDataRU.biography,
                    langCode: 'RU'
                },
                {
                    name: formDataEN.name,
                    surname: formDataEN.surname,
                    biography: formDataEN.biography,
                    langCode: 'EN'
                }
            ]

        } as AuthorBody

        if (isEdit) {
            let langList = [];
            if (author) {
                for (let i = 0; i < author.authorLangList.length; i++) {
                    if (author.authorLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                authorLangId: author.authorLangList[i].authorLangId,
                                name: formDataTR.name,
                                surname: formDataTR.surname,
                                biography: formDataTR.biography,
                                langCode: 'TR'
                            }
                        );
                    } else if (author.authorLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                authorLangId: author.authorLangList[i].authorLangId,
                                name: formDataRU.name,
                                surname: formDataRU.surname,
                                biography: formDataRU.biography,
                                langCode: 'RU'
                            }
                        );
                    } else if (author.authorLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                authorLangId: author.authorLangList[i].authorLangId,
                                name: formDataEN.name,
                                surname: formDataEN.surname,
                                biography: formDataEN.biography,
                                langCode: 'EN'
                            }
                        );
                    }
                }
                if (image) {
                    const updateImage = image.split('data:image/');
                    if (updateImage.length === 2) {
                        if (author.image) {
                            body.image = {
                                imageId: author.image.imageId,
                                value: image.split(';base64,')[1],
                                extension: image.split('data:image/')[1].split(';base64,')[0]
                            } as CreateImage
                        } else {
                            body.image = {
                                value: image.split(';base64,')[1],
                                extension: image.split('data:image/')[1].split(';base64,')[0]
                            } as CreateImage
                        }
                    } else {
                        body.image = {
                            imageId: author.image.imageId
                        } as CreateImage
                    }
                }
                body.authorLangList = langList;
                body.authorId = author.authorId;
                update(body);
            }
        } else {
            if (image) {
                body.image = {
                    value: image.split(';base64,')[1],
                    extension: image.split('data:image/')[1].split(';base64,')[0]
                } as CreateImage
            }
            create(body);

            setFormDataTR({
                name: '',
                surname: '',
                biography: ''
            });
            setFormDataRU({
                name: '',
                surname: '',
                biography: ''
            })
            setFormDataEN({
                name: '',
                surname: '',
                biography: ''
            });
            setImage(undefined);

        }

    };


    return (
        <div className='author-add-edit'>
            <div className='author-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='author-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className="author-add-edit__form__item author_image">
                    <UploadImage
                        isMulti={false}
                        preview={image}
                        onImageSelect={setImage}
                    />
                </div>
                <div className="author-add-edit__form__item">
                    <p className="author-add-edit__form__item_label">TR</p>
                    <ul className="author-add-edit__form__item_input">
                        <li>
                            <TextInput
                                placeholder='Name'
                                name='name'
                                onChange={handleChangeTR}
                                value={formDataTR.name}
                            />
                        </li>
                        <li>
                            <TextInput
                                placeholder='Surname'
                                name='surname'
                                onChange={handleChangeTR}
                                value={formDataTR.surname}
                            />
                        </li>
                        <li>
                            <TextArea
                                placeholder='Biography'
                                name='biography'
                                onChange={handleChangeTR}
                                value={formDataTR.biography}
                            />
                        </li>
                    </ul>
                </div>
                <div className="author-add-edit__form__item">
                    <p className="author-add-edit__form__item_label">RU</p>
                    <ul className="author-add-edit__form__item_input">
                        <li>
                            <TextInput
                                placeholder='Name'
                                name='name'
                                onChange={handleChangeRU}
                                value={formDataRU.name}
                            />
                        </li>
                        <li>
                            <TextInput
                                placeholder='Surname'
                                name='surname'
                                onChange={handleChangeRU}
                                value={formDataRU.surname}
                            />
                        </li>
                        <li>
                            <TextArea
                                placeholder='Biography'
                                name='biography'
                                onChange={handleChangeRU}
                                value={formDataRU.biography}
                            />
                        </li>
                    </ul>
                </div>
                <div className="author-add-edit__form__item">
                    <p className="author-add-edit__form__item_label">EN</p>
                    <ul className="author-add-edit__form__item_input">
                        <li>
                            <TextInput
                                placeholder='Name'
                                name='name'
                                onChange={handleChangeEN}
                                value={formDataEN.name}
                            />
                        </li>
                        <li>
                            <TextInput
                                placeholder='Surname'
                                name='surname'
                                onChange={handleChangeEN}
                                value={formDataEN.surname}
                            />
                        </li>
                        <li>
                            <TextArea
                                placeholder='Biography'
                                name='biography'
                                onChange={handleChangeEN}
                                value={formDataEN.biography}
                            />
                        </li>
                    </ul>
                </div>
                <div className="author-add-edit__form__item flex-author-form-btn">
                    <div className='author-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditAddAuthor;