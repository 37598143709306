import React from 'react';
import {useParams} from 'react-router-dom';
import EditAddPublisherHouse from './EditAddPublisherHouse';
import {useGetPublisherHouse} from '../../hooks/PublisherHouse/usePublisherHouseQueries';

const PublisherHouseEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetPublisherHouse(Number(id));


    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess
                        ?
                        <EditAddPublisherHouse publisherHouses={data.success.data} isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );


};

export default PublisherHouseEdit;