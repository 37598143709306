import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {MainLayout} from './layouts';
import {
    Author,
    AuthorAdd,
    AuthorEdit,
    Brand,
    BrandAdd,
    BrandEdit,
    BookType,
    BookTypeAdd,
    BookTypeEdit,
    BookSegment,
    BookSegmentAdd,
    BookSegmentEdit,
    BookPaperQuality,
    BookPaperQualityAdd,
    BookPaperQualityEdit,
    BookCoverType,
    BookCoverTypeAdd,
    BookCoverTypeEdit,
    BookContentLanguage,
    BookContentLanguageAdd,
    BookContentLanguageEdit,
    BookPublishingBrand,
    BookPublishingBrandAdd,
    BookPublishingBrandEdit,
    Category,
    CategoryAdd,
    CategoryEdit,
    Home,
    Login,
    Menu,
    MenuAdd,
    MenuEdit,
    Product,
    ProductAdd,
    ProductEdit,
    ProductType,
    ProductTypeAdd,
    ProductTypeEdit,
    Property,
    PropertyAdd,
    PropertyEdit,
    PublisherHouse,
    PublisherHouseAdd,
    PublisherHouseEdit,
    Series,
    SeriesAdd,
    SeriesEdit
} from './pages';
import {Toaster} from 'react-hot-toast';
import RequireAuth from './hoc/RequireAuth';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route index element={
                        <RequireAuth>
                            <Home/>
                        </RequireAuth>
                    }/>
                    <Route path='category' element={
                        <RequireAuth>
                            <Category/>
                        </RequireAuth>
                    }/>
                    <Route path='category/add' element={
                        <RequireAuth>
                            <CategoryAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='category/edit/:id' element={
                        <RequireAuth>
                            <CategoryEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='menu' element={
                        <RequireAuth>
                            <Menu/>
                        </RequireAuth>
                    }/>
                    <Route path='menu/add' element={
                        <RequireAuth>
                            <MenuAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='menu/edit/:id' element={
                        <RequireAuth>
                            <MenuEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='brand' element={
                        <RequireAuth>
                            <Brand/>
                        </RequireAuth>
                    }/>
                    <Route path='brand/add' element={
                        <RequireAuth>
                            <BrandAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='brand/edit/:id' element={
                        <RequireAuth>
                            <BrandEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='book-type' element={
                        <RequireAuth>
                            <BookType/>
                        </RequireAuth>
                    }/>
                    <Route path='book-type/add' element={
                        <RequireAuth>
                            <BookTypeAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='book-type/edit/:id' element={
                        <RequireAuth>
                            <BookTypeEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='book-segment' element={
                        <RequireAuth>
                            <BookSegment/>
                        </RequireAuth>
                    }/>
                    <Route path='book-segment/add' element={
                        <RequireAuth>
                            <BookSegmentAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='book-segment/edit/:id' element={
                        <RequireAuth>
                            <BookSegmentEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='book-paper-quality' element={
                        <RequireAuth>
                            <BookPaperQuality/>
                        </RequireAuth>
                    }/>
                    <Route path='book-paper-quality/add' element={
                        <RequireAuth>
                            <BookPaperQualityAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='book-paper-quality/edit/:id' element={
                        <RequireAuth>
                            <BookPaperQualityEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='book-cover-type' element={
                        <RequireAuth>
                            <BookCoverType/>
                        </RequireAuth>
                    }/>
                    <Route path='book-cover-type/add' element={
                        <RequireAuth>
                            <BookCoverTypeAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='book-cover-type/edit/:id' element={
                        <RequireAuth>
                            <BookCoverTypeEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='book-content-language' element={
                        <RequireAuth>
                            <BookContentLanguage/>
                        </RequireAuth>
                    }/>
                    <Route path='book-content-language/add' element={
                        <RequireAuth>
                            <BookContentLanguageAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='book-content-language/edit/:id' element={
                        <RequireAuth>
                            <BookContentLanguageEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='book-publishing-brand' element={
                        <RequireAuth>
                            <BookPublishingBrand/>
                        </RequireAuth>
                    }/>
                    <Route path='book-publishing-brand/add' element={
                        <RequireAuth>
                            <BookPublishingBrandAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='book-publishing-brand/edit/:id' element={
                        <RequireAuth>
                            <BookPublishingBrandEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='publisher-house' element={
                        <RequireAuth>
                            <PublisherHouse/>
                        </RequireAuth>
                    }/>
                    <Route path='publisher-house/add' element={
                        <RequireAuth>
                            <PublisherHouseAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='publisher-house/edit/:id' element={
                        <RequireAuth>
                            <PublisherHouseEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='series' element={
                        <RequireAuth>
                            <Series/>
                        </RequireAuth>
                    }/>
                    <Route path='series/add' element={
                        <RequireAuth>
                            <SeriesAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='series/edit/:id' element={
                        <RequireAuth>
                            <SeriesEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='author' element={
                        <RequireAuth>
                            <Author/>
                        </RequireAuth>
                    }/>
                    <Route path='author/add' element={
                        <RequireAuth>
                            <AuthorAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='author/edit/:id' element={
                        <RequireAuth>
                            <AuthorEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='property' element={
                        <RequireAuth>
                            <Property/>
                        </RequireAuth>
                    }/>
                    <Route path='property/add' element={
                        <RequireAuth>
                            <PropertyAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='property/edit/:id' element={
                        <RequireAuth>
                            <PropertyEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='product-type' element={
                        <RequireAuth>
                            <ProductType/>
                        </RequireAuth>
                    }/>
                    <Route path='product-type/add' element={
                        <RequireAuth>
                            <ProductTypeAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='product-type/edit/:id' element={
                        <RequireAuth>
                            <ProductTypeEdit/>
                        </RequireAuth>
                    }/>
                    <Route path='product' element={
                        <RequireAuth>
                            <Product/>
                        </RequireAuth>
                    }/>
                    <Route path='product/add' element={
                        <RequireAuth>
                            <ProductAdd/>
                        </RequireAuth>
                    }/>
                    <Route path='product/edit/:id' element={
                        <RequireAuth>
                            <ProductEdit/>
                        </RequireAuth>
                    }/>
                </Route>
                <Route path='/login' element={<Login/>}/>
            </Routes>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </BrowserRouter>
    );
}

export default App;
