// LoginButton.tsx
import React from 'react';
import '../../../styles/components/UI/Button.scss';

interface LoginButtonProps {
    title: string;
    onClick?: (e: any) => void;
}

const SubmitButton: React.FC<LoginButtonProps> = ({title}) => {
    return (
        <button type="submit" className='submit-button'>{title}</button>
    );
};

export default SubmitButton;
