import '../../styles/pages/category.scss';
import React, {FC, useEffect, useState} from 'react';
import {Dropdown, SubmitButton, TextInput} from '../../components';
import {Category, CategoryBody, CategoryContent} from '../../models';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {useCategoriesQuery} from '../../hooks';
import {useCategoryCreate, useCategoryUpdate} from '../../hooks/Category/useCategoryMutation';


interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
    slug: string;
}

interface Props {
    categoriesExclude?: CategoryContent[];
    category?: Category;
    isEdit: boolean;
}

const EditAddCategory: FC<Props> = ({isEdit, category, categoriesExclude}) => {
    const {data, isSuccess, refetch} = useCategoriesQuery(0, 20);
    const {mutate: create} = useCategoryCreate(refetch);
    const {mutate: update} = useCategoryUpdate(refetch);

    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: '',
        slug: '',
    });
    const [parent, setParent] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('');
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (isEdit) {
            if (category) {
                if ('categoryId' in category) {
                    for (let i = 0; i < category.categoryLangList.length; i++) {
                        if (category.categoryLangList[i].langCode === 'TR') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameTR: category.categoryLangList[i].name,
                            }));
                        } else if (category.categoryLangList[i].langCode === 'RU') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameRU: category.categoryLangList[i].name,
                            }));
                        } else if (category.categoryLangList[i].langCode === 'EN') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameEN: category.categoryLangList[i].name,
                            }));
                        }
                    }
                    if (category.parentId) {
                        setParent(category.parentId.toString());
                        setSelectedOption(category.parentId.toString());
                    }
                    setFormData((prevData) => ({
                        ...prevData,
                        slug: category.shortCode,
                    }));
                }
            }
        }
    }, [category]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: CategoryBody = {
            image: {
                value: 'string',
                extension: 'string'
            },
            shortCode: formData.slug,
            categoryLangList: [
                {
                    name: formData.nameTR,
                    langCode: 'TR'
                },
                {
                    name: formData.nameRU,
                    langCode: 'RU'
                },
                {
                    name: formData.nameEN,
                    langCode: 'EN'
                }
            ]

        } as CategoryBody
        if (parent) body.parentId = Number(parent);
        if (isEdit) {
            let langList = []
            if (category) {
                for (let i = 0; i < category.categoryLangList.length; i++) {
                    if (category.categoryLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                categoryLangId: category.categoryLangList[i].categoryLangId,
                                name: formData.nameTR,
                                langCode: 'TR'
                            }
                        );
                    } else if (category.categoryLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                categoryLangId: category.categoryLangList[i].categoryLangId,
                                name: formData.nameRU,
                                langCode: 'RU'
                            }
                        );
                    } else if (category.categoryLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                categoryLangId: category.categoryLangList[i].categoryLangId,
                                name: formData.nameEN,
                                langCode: 'EN'
                            }
                        );
                    }
                }
            }
            body.categoryLangList = langList
            body.categoryId = category?.categoryId;
            update(body);

        } else {
            create(body);
            // createCategory(body, navigate);
            setFormData({
                nameTR: '',
                nameRU: '',
                nameEN: '',
                slug: '',
            });
            setParent('');
            setSelectedOption('');
        }

    };

    return (
        <div className='category-add-edit'>
            <div className='category-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='category-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className="category-add-edit__form__item">
                    <Dropdown
                        title='Select parent category'
                        onSelect={setParent}
                        options={isEdit ? categoriesExclude ? categoriesExclude : [] : isSuccess ? data.success.data.content : []}
                        selectedValue={parent}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                </div>
                <div className="category-add-edit__form__item">
                    <TextInput
                        placeholder='TR Name'
                        name='nameTR'
                        onChange={handleChange}
                        value={formData.nameTR}
                    />
                </div>
                <div className="category-add-edit__form__item">
                    <TextInput
                        placeholder='RU Name'
                        name='nameRU'
                        onChange={handleChange}
                        value={formData.nameRU}
                    />
                </div>
                <div className="category-add-edit__form__item">
                    <TextInput
                        placeholder='EN Name'
                        name='nameEN'
                        onChange={handleChange}
                        value={formData.nameEN}
                    />
                </div>
                <div className="category-add-edit__form__item">
                    <TextInput
                        placeholder='Short Code'
                        name='slug'
                        onChange={handleChange}
                        value={formData.slug}
                    />
                </div>
                <div className="category-add-edit__form__item flex-category-form-btn">
                    <div className='category-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditAddCategory;