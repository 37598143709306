import {client} from "../api";
import {Author, AuthorBody, Authors, ResponseError, ResponseSuccess} from "../models";


export async function getAuthor(id: number): Promise<ResponseSuccess<Author> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Author> | ResponseError>(`/api/v1/admin/authors/${id}`)).data;
}

export async function getAuthors(page: number, pageSize: number): Promise<ResponseSuccess<Authors> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Authors> | ResponseError>(`/api/v1/admin/authors/table-items/browse?page=${page}&size=${pageSize}`)).data;
}


export async function getFilterAuthors(name: string, page: number, pageSize: number): Promise<ResponseSuccess<Authors> | ResponseError | any> {
    return await client.get<ResponseSuccess<Authors> | ResponseError>(`/api/v1/admin/authors/browse?name=${name}&page=${page}&size=${pageSize}`);
}


export async function createAuthor(body: AuthorBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/authors`, body)).data;
}


export async function updateAuthor(body: AuthorBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/authors`, body)).data;
}

export async function deleteAuthor(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/authors/${id}`)).data;
}