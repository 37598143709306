import React, {FC, ReactElement} from 'react';
import '../../../styles/components/UI/Button.scss';

interface Props {
    icon?: ReactElement
    title?: string;
    onClick?: (e: any) => void;
}

const AddButton: FC<Props> = ({onClick, title, icon}) => {
    return (
        <button className='btn-add' onClick={onClick}>{title ? title : icon ? icon : 'Add'}</button>
    );
};

export default AddButton;