import '../../styles/pages/bookContentLanguage.scss';
import React, {FC, useEffect, useState} from 'react';
import {SubmitButton, TextInput} from '../../components';
import {BookContentLanguage, BookContentLanguageBody} from '../../models';
import {useNavigate} from 'react-router-dom';
import {useCreateBookContentLanguage, useGetBookContentLanguages, useUpdateBookContentLanguage} from '../../hooks';
import FormLayout from '../../components/Layout/FormLayout';


interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
}

interface Props {
    bookContentLanguage?: BookContentLanguage;
    isEdit: boolean;
}

const EditAddBookContentLanguage: FC<Props> = ({isEdit, bookContentLanguage}) => {
    const {refetch} = useGetBookContentLanguages(0, 20);
    const {mutate: create} = useCreateBookContentLanguage(refetch);
    const {mutate: update} = useUpdateBookContentLanguage(refetch);

    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: '',
    });
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (isEdit) {
            if (bookContentLanguage) {
                if ('bookContentLanguageId' in bookContentLanguage) {
                    for (let i = 0; i < bookContentLanguage.bookContentLanguageLangList.length; i++) {
                        if (bookContentLanguage.bookContentLanguageLangList[i].langCode === 'TR') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameTR: bookContentLanguage.bookContentLanguageLangList[i].name,
                            }));
                        } else if (bookContentLanguage.bookContentLanguageLangList[i].langCode === 'RU') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameRU: bookContentLanguage.bookContentLanguageLangList[i].name,
                            }));
                        } else if (bookContentLanguage.bookContentLanguageLangList[i].langCode === 'EN') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameEN: bookContentLanguage.bookContentLanguageLangList[i].name,
                            }));
                        }
                    }
                }
            }
        }
    }, [bookContentLanguage]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: BookContentLanguageBody = {
            bookContentLanguageLangList: [
                {
                    name: formData.nameTR,
                    langCode: 'TR'
                },
                {
                    name: formData.nameRU,
                    langCode: 'RU'
                },
                {
                    name: formData.nameEN,
                    langCode: 'EN'
                }
            ]

        } as BookContentLanguageBody;

        if (isEdit) {
            let langList = []
            if (bookContentLanguage) {
                for (let i = 0; i < bookContentLanguage.bookContentLanguageLangList.length; i++) {
                    if (bookContentLanguage.bookContentLanguageLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                bookContentLanguageLangId: bookContentLanguage.bookContentLanguageLangList[i].bookContentLanguageLangId,
                                name: formData.nameTR,
                                langCode: 'TR'
                            }
                        );
                    } else if (bookContentLanguage.bookContentLanguageLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                bookContentLanguageLangId: bookContentLanguage.bookContentLanguageLangList[i].bookContentLanguageLangId,
                                name: formData.nameRU,
                                langCode: 'RU'
                            }
                        );
                    } else if (bookContentLanguage.bookContentLanguageLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                bookContentLanguageLangId: bookContentLanguage.bookContentLanguageLangList[i].bookContentLanguageLangId,
                                name: formData.nameEN,
                                langCode: 'EN'
                            }
                        );
                    }
                }
            }
            body.bookContentLanguageLangList = langList
            body.bookContentLanguageId = bookContentLanguage?.bookContentLanguageId;
            update(body);

        } else {
            create(body);
            setFormData({
                nameTR: '',
                nameRU: '',
                nameEN: ''
            });
        }

    };

    return (
        <FormLayout child={
            <form className='book-content-language-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className="book-content-language-add-edit__form__item">
                    <TextInput
                        placeholder='TR Name'
                        name='nameTR'
                        onChange={handleChange}
                        value={formData.nameTR}
                    />
                </div>
                <div className="book-content-language-add-edit__form__item">
                    <TextInput
                        placeholder='RU Name'
                        name='nameRU'
                        onChange={handleChange}
                        value={formData.nameRU}
                    />
                </div>
                <div className="book-content-language-add-edit__form__item">
                    <TextInput
                        placeholder='EN Name'
                        name='nameEN'
                        onChange={handleChange}
                        value={formData.nameEN}
                    />
                </div>
                <div className="book-content-language-add-edit__form__item flex-book-content-language-form-btn">
                    <div className='book-content-language-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        }/>
    );
};

export default EditAddBookContentLanguage;