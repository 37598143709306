import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {BookContentLanguages, BookContentLanguage, ResponseSuccess} from "../../models";
import {bookContentLanguageService} from "../../service";


const useGetBookContentLanguages = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<BookContentLanguages>>({
        queryFn: () => bookContentLanguageService.getBookContentLanguages(page, pageSize),
        queryKey: ['book-content-languages'],
        placeholderData: keepPreviousData
    })
}


const useGetBookContentLanguage = (id: number) => {
    return useQuery<ResponseSuccess<BookContentLanguage>>({
        queryFn: () => bookContentLanguageService.getBookContentLanguageByID(id),
        queryKey: ['book-content-language', id]
    })
}


export {
    useGetBookContentLanguages,
    useGetBookContentLanguage
};