import '../../styles/pages/productType.scss';
import React, {FC, useEffect, useState} from 'react';
import {MultiSelect, SubmitButton, TextInput} from '../../components';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {useGetAvailableProperties, useGetProductType, useUpdateProductType} from '../../hooks';
import {AvailableProperties, CreateProductTypeBody, ProductType} from '../../models';

const options = [
    {value: '1', label: 'Option 1'},
    {value: '2', label: 'Option 2'},
    {value: '3', label: 'Option 3'},
    {value: '4', label: 'Option 4'},
    {value: '5', label: 'Option 5'},
    {value: '6', label: 'Option 6'},
    {value: '7', label: 'Option 7'},
    {value: '8', label: 'Option 8'},
    {value: '9', label: 'Option 9'},
    {value: '10', label: 'Option 10'},
    {value: '11', label: 'Option 11'},
    {value: '12', label: 'Option 12'},
    {value: '13', label: 'Option 6'},
    {value: '14', label: 'Option 7'},
    {value: '15', label: 'Option 8'},
    {value: '16', label: 'Option 9'},
    {value: '17', label: 'Option 10'},
    {value: '18', label: 'Option 11'},
    {value: '19', label: 'Option 12'},
];

interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
}

interface Props {
    productType: ProductType;
    isEdit: boolean;
    productTypeId: number;
    availableProperties: AvailableProperties[];
}

const EditProductType: FC<Props> = ({availableProperties, productType, productTypeId}) => {
    const {data, isSuccess, refetch} = useGetAvailableProperties(productTypeId);
    const {refetch: refetchProduct} = useGetProductType(productTypeId);
    const {mutate: update} = useUpdateProductType(refetchProduct, refetch);
    const [properties, setProperties] = useState<AvailableProperties[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: ''
    });

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (productType) {
            if ('productTypeId' in productType) {
                for (let i = 0; i < productType.productTypeLangList.length; i++) {
                    if (productType.productTypeLangList[i].langCode === 'TR') {
                        setFormData((prevData) => ({
                            ...prevData,
                            nameTR: productType.productTypeLangList[i].name
                        }));
                    } else if (productType.productTypeLangList[i].langCode === 'RU') {
                        setFormData((prevData) => ({
                            ...prevData,
                            nameRU: productType.productTypeLangList[i].name
                        }));
                    } else if (productType.productTypeLangList[i].langCode === 'EN') {
                        setFormData((prevData) => ({
                            ...prevData,
                            nameEN: productType.productTypeLangList[i].name
                        }));
                    }
                }
            }
        }
    }, [productType]);

    useEffect(() => {
        let tempArray = []
        let availableProperties_ = availableProperties.concat(productType.productPropertyList)
        setProperties(availableProperties_.reverse())
        for (let i = 0; i < productType.productPropertyList.length; i++) {
            tempArray.push(productType.productPropertyList[i].productPropertyId)
        }
        setSelectedOptions(tempArray)
    }, [availableProperties])

    useEffect(() => {
        if (isSuccess) {
            let tempArray = []
            let available = data.success.data
            let availableProperties_ = available.concat(productType.productPropertyList)
            setProperties(availableProperties_.reverse())
            for (let i = 0; i < productType.productPropertyList.length; i++) {
                tempArray.push(productType.productPropertyList[i].productPropertyId)
            }
            setSelectedOptions(tempArray)
        }

    }, [isSuccess]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let langList = []
        for (let i = 0; i < productType.productTypeLangList.length; i++) {
            if (productType.productTypeLangList[i].langCode === 'TR') {
                langList.push(
                    {
                        productTypeLangId: productType.productTypeLangList[i].productTypeLangId,
                        name: formData.nameTR,
                        langCode: 'TR'
                    }
                )
            } else if (productType.productTypeLangList[i].langCode === 'RU') {
                langList.push(
                    {
                        productTypeLangId: productType.productTypeLangList[i].productTypeLangId,
                        name: formData.nameRU,
                        langCode: 'RU'
                    }
                )
            } else if (productType.productTypeLangList[i].langCode === 'EN') {
                langList.push(
                    {
                        productTypeLangId: productType.productTypeLangList[i].productTypeLangId,
                        name: formData.nameEN,
                        langCode: 'EN'
                    }
                )
            }
        }
        const body: CreateProductTypeBody = {
            productTypeId: productType.productTypeId,
            productTypeLangList: langList,
            productPropertyIdList: selectedOptions

        } as CreateProductTypeBody;
        update(body)
    };

    return (
        <div className='product-type-add-edit'>
            <div className='product-type-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='product-type-add-edit__form' action="" onSubmit={handleSubmit}>

                <div className='product-type-add-edit_table'>
                    <div className='product-type-add-edit_table_left'>
                        <div className="product-type-add-edit__form__item">
                            <TextInput
                                placeholder='TR Name'
                                name='nameTR'
                                onChange={handleChange}
                                value={formData.nameTR}
                            />
                        </div>
                        <div className="product-type-add-edit__form__item">
                            <TextInput
                                placeholder='RU Name'
                                name='nameRU'
                                onChange={handleChange}
                                value={formData.nameRU}
                            />
                        </div>
                        <div className="product-type-add-edit__form__item">
                            <TextInput
                                placeholder='EN Name'
                                name='nameEN'
                                onChange={handleChange}
                                value={formData.nameEN}
                            />
                        </div>
                    </div>
                    <div className='product-type-add-edit_table_right'>
                        <MultiSelect
                            label='Property'
                            options={properties}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                        />
                    </div>
                </div>

                <div className="product-type-add-edit__form__item flex-product-type-form-btn">
                    <div className='product-type-add-edit__form__btn'>
                        <SubmitButton
                            title='Update'
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditProductType;