import '../../styles/pages/product.scss';
import React, {useState} from 'react';
import FormLayout from '../../components/Layout/FormLayout';
import {Dropdown, MultiImageUpload, MultiSelect, RadioButton, SubmitButton} from '../../components';
import GeneralInput from './GeneralInput';
import ProductLangList from './ProductLangList';
import {CKEditorEventPayload} from 'ckeditor4-react';
import PureProduct from './PureProduct';
import {useCategoriesQuery, useGetBrands, useGetProducts, useProductCreate, useProductTypes} from '../../hooks';
import Book from './Book';
import ProductProperty from './ProductProperty';
import uuid from 'react-uuid';
import {getImageListBody, getPropertyListBody} from '../../mapper/product.mapper';
import {
    Book as BookModel,
    CreateProductBody,
    PureProduct as PureProductModel
} from '../../models';


export interface PropertyInput {
    id: number;
    placeholder: string;
    name: string;
    value: string;
}

export interface BookInputType {
    isbn: string;
    pageCount: number;
    editionDate: string;
    ageLimitation: string;
    bookSize: string;
    weight: number;
    publisherCodeOfProduct: string;
    revisionNumber: string;
}

export interface GeneralInputType {
    isPublished: boolean;
    price: number;
    availableStock: number;
    barcode: string;
}

export interface ImageListProduct {
    index: string;
    value: string;
    extension: string;
    imageOrder: number;
    isActive: boolean;
}


export interface ProductLangListInputs {
    title: string
    description: string
    langCode: string
}

const ProductAdd = () => {
    const {data, isSuccess} = useGetBrands(0, 20);
    const {refetch} = useGetProducts(0, 20);
    const {mutate: create} = useProductCreate(refetch);
    const {data: categoriesData, isSuccess: isSuccessCategories} = useCategoriesQuery(0, 20);
    const {data: productTypeData, isSuccess: isSuccessProductType} = useProductTypes(0, 20);

    const [generalInput, setGeneralInput] = useState<GeneralInputType>({
        isPublished: false,
        price: 0,
        availableStock: 0,
        barcode: ''
    });

    const [productLangListTR, setProductLangListTR] = useState<ProductLangListInputs>({
        title: '',
        description: '',
        langCode: 'TR'
    });

    const [productLangListRU, setProductLangListRU] = useState<ProductLangListInputs>({
        title: '',
        description: '',
        langCode: 'RU'
    });

    const [productLangListEN, setProductLangListEN] = useState<ProductLangListInputs>({
        title: '',
        description: '',
        langCode: 'EN'
    });

    const [radioButton, setRadioButton] = useState<string>("book");

    const [brand, setBrand] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('');

    const [productType, setProductType] = useState<string>('');
    const [selectedOptionProductType, setSelectedOptionProductType] = useState<string>('');

    const [categories, setCategories] = useState<number[]>([]);


    const [bookInput, setBookInput] = useState<BookInputType>({
        isbn: '',
        pageCount: 0,
        editionDate: '',
        ageLimitation: '',
        bookSize: '',
        weight: 0,
        publisherCodeOfProduct: '',
        revisionNumber: ''
    });

    const [publisherHouse, setPublisherHouse] = useState<string>('');
    const [bookType, setBookType] = useState<string>('');
    const [bookSegment, setBookSegment] = useState<string>('');
    const [bookPaperQuality, setBookPaperQuality] = useState<string>('');
    const [bookCoverType, setBookCoverType] = useState<string>('');
    const [bookContentLanguage, setBookContentLanguage] = useState<string>('');
    const [bookPublishingBrand, setBookPublishingBrand] = useState<string>('');

    const [authors, setAuthors] = useState<number[]>([]);
    const [series, setSeries] = useState<number[]>([]);

    const onChangeBookInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setBookInput((prevData: BookInputType) => ({
            ...prevData,
            [name]: value,
        }));

    };


    const [propertyInputTR, setPropertyInputTR] = useState<PropertyInput[]>(
        [
            {
                id: 0,
                name: '',
                placeholder: '',
                value: ''
            }
        ]
    );

    const [propertyInputRU, setPropertyInputRU] = useState<PropertyInput[]>(
        [
            {
                id: 0,
                name: '',
                placeholder: '',
                value: ''
            }
        ]
    );

    const [propertyInputEN, setPropertyInputEN] = useState<PropertyInput[]>(
        [
            {
                id: 0,
                name: '',
                placeholder: '',
                value: ''
            }
        ]
    );


    const onChangePropertyInputTR = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const {name, value} = event.target;
        setPropertyInputTR(
            propertyInputTR.map((item, index) =>
                index === id ? {...item, value: value} : item
            )
        );
    }

    const onChangePropertyInputRU = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const {name, value} = event.target;
        setPropertyInputRU(
            propertyInputRU.map((item, index) =>
                index === id ? {...item, value: value} : item
            )
        );
    }

    const onChangePropertyInputEN = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const {name, value} = event.target;
        setPropertyInputEN(
            propertyInputEN.map((item, index) =>
                index === id ? {...item, value: value} : item
            )
        );
    }


    const [imageArr, setImageArr] = useState<ImageListProduct[]>(
        [
            {
                index: uuid(),
                value: '',
                extension: '',
                imageOrder: 0,
                isActive: false
            }
        ]
    );


    const uploadImageEvent = (items: ImageListProduct[]) => {
        setImageArr(items)
    }


    const handleGeneralInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        let tempValue: string | boolean = value;
        if (name === "isPublished") {
            tempValue = !generalInput.isPublished;
        }
        setGeneralInput((prevData: GeneralInputType) => ({
            ...prevData,
            [name]: tempValue,
        }));
    };


    const handleProductLangListChangeTR = (e: React.ChangeEvent<HTMLInputElement> | CKEditorEventPayload<"change">) => {
        if ('target' in e) {
            const {name, value} = e.target;
            setProductLangListTR((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setProductLangListTR((prevData) => ({
                ...prevData,
                description: e.editor.getData() as string,
            }));
        }
    };


    const handleProductLangListChangeRU = (e: React.ChangeEvent<HTMLInputElement> | CKEditorEventPayload<"change">) => {
        if ('target' in e) {
            const {name, value} = e.target;
            setProductLangListRU((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setProductLangListRU((prevData) => ({
                ...prevData,
                description: e.editor.getData() as string,
            }));
        }
    };


    const handleProductLangListChangeEN = (e: React.ChangeEvent<HTMLInputElement> | CKEditorEventPayload<"change">) => {
        if ('target' in e) {
            const {name, value} = e.target;
            setProductLangListEN((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setProductLangListEN((prevData) => ({
                ...prevData,
                description: e.editor.getData() as string,
            }));
        }
    };


    const handleRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setRadioButton(value);
    }


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: CreateProductBody = {
            imageList: getImageListBody(imageArr),
            barcode: generalInput.barcode,
            isPublished: generalInput.isPublished,
            price: generalInput.price,
            availableStock: generalInput.availableStock,
            productLangList: [
                productLangListTR,
                productLangListRU,
                productLangListEN
            ],
            categoryIdList: categories,
        } as CreateProductBody

        if (radioButton === "product") {
            body.pureProduct = {
                brandId: Number(brand)
            } as PureProductModel;

        } else {
            let date = bookInput.editionDate.split("-");
            body.book = {
                isbn: bookInput.isbn,
                pageCount: bookInput.pageCount,
                editionDate: date[2] + "/" + date[1] + "/" + date[0],
                ageLimitation: bookInput.ageLimitation,
                bookSize: bookInput.bookSize,
                weight: bookInput.weight,
                publisherCodeOfProduct: bookInput.publisherCodeOfProduct,
                revisionNumber: bookInput.revisionNumber,
                publisherHouseId: Number(publisherHouse),
                bookTypeId: Number(bookType),
                bookSegmentId: Number(bookSegment),
                bookPaperQualityId: Number(bookPaperQuality),
                bookCoverTypeId: Number(bookCoverType),
                bookContentLanguageId: Number(bookContentLanguage),
                bookPublishingLanguageId: Number(bookPublishingBrand),
                authorIdList: authors,
                seriesIdList: series
            } as BookModel
        }
        body.productTypeId = Number(productType);
        body.productPropertyValueList = getPropertyListBody(
            propertyInputTR,
            propertyInputRU,
            propertyInputEN
        );
        console.log(body);
        create(body);
    }


    return (
        <FormLayout child={
            <form className='product-add' action="" onSubmit={handleSubmit}>

                <div className="product-add__images">
                    <MultiImageUpload
                        imageLimit={6}
                        images={imageArr}
                        uploadImageEvent={uploadImageEvent}
                    />
                </div>

                <GeneralInput
                    generalInputItem={generalInput}
                    onChange={handleGeneralInputChange}
                />
                <div className='product-add_lang_list'>
                    <ProductLangList
                        lang='TR'
                        langList={productLangListTR}
                        onChange={handleProductLangListChangeTR}
                    />
                    <ProductLangList
                        lang='RU'
                        langList={productLangListRU}
                        onChange={handleProductLangListChangeRU}
                    />
                    <ProductLangList
                        lang='EN'
                        langList={productLangListEN}
                        onChange={handleProductLangListChangeEN}
                    />
                </div>
                <div className='product-add_categories'>
                    <MultiSelect
                        label='Categories'
                        options={isSuccessCategories ? categoriesData.success.data.content : []}
                        selectedOptions={categories}
                        setSelectedOptions={setCategories}
                    />
                </div>
                <div className='product-add-switch'>
                    <fieldset id="group1" className='product-add-radio-group'>
                        <div className='product-add-radio-button'>
                            <RadioButton
                                label='Book'
                                value="book"
                                name="book"
                                checked={radioButton === "book"}
                                onChange={handleRadioButton}
                            />
                        </div>
                        <div className='product-add-radio-button'>
                            <RadioButton
                                label='Product'
                                value="product"
                                name="product"
                                checked={radioButton === "product"}
                                onChange={handleRadioButton}
                            />
                        </div>
                    </fieldset>
                </div>
                {
                    radioButton === 'product'
                        ?
                        <PureProduct
                            onSelect={setBrand}
                            selectedValue={brand}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            brands={isSuccess ? data.success.data.content : []}
                        />
                        :
                        <Book
                            authors={authors}
                            bookInput={bookInput}
                            publisherHouse={publisherHouse}
                            bookType={bookType}
                            bookSegment={bookSegment}
                            bookPaperQuality={bookPaperQuality}
                            bookCoverType={bookCoverType}
                            bookContentLanguage={bookContentLanguage}
                            bookPublishingBrand={bookPublishingBrand}
                            series={series}
                            onChangeBookInput={onChangeBookInput}
                            setAuthors={setAuthors}
                            setPublisherHouse={setPublisherHouse}
                            setBookType={setBookType}
                            setBookSegment={setBookSegment}
                            setBookPaperQuality={setBookPaperQuality}
                            setBookCoverType={setBookCoverType}
                            setBookContentLanguage={setBookContentLanguage}
                            setBookPublishingBrand={setBookPublishingBrand}
                            setSeries={setSeries}
                        />
                }
                <div>
                    <Dropdown
                        onSelect={setProductType}
                        options={isSuccessProductType ? productTypeData.success.data.content : []}
                        selectedOption={selectedOptionProductType}
                        selectedValue={productType}
                        setSelectedOption={setSelectedOptionProductType}
                        title='Select Product Type'
                    />
                </div>
                <div>
                    {
                        productType === ""
                            ?
                            <></>
                            :
                            <ProductProperty
                                productTypeId={Number(productType)}
                                propertyInputTR={propertyInputTR}
                                propertyInputRU={propertyInputRU}
                                propertyInputEN={propertyInputEN}
                                setPropertyInputTR={setPropertyInputTR}
                                setPropertyInputRU={setPropertyInputRU}
                                setPropertyInputEN={setPropertyInputEN}
                                onChangeTR={onChangePropertyInputTR}
                                onChangeRU={onChangePropertyInputRU}
                                onChangeEN={onChangePropertyInputEN}
                            />
                    }
                </div>
                <div className="product-add__form__item flex-product-add-form-btn">
                    <div className='product-add__form__btn'>
                        <SubmitButton
                            title='Save'
                        />
                    </div>
                </div>
            </form>
        }/>
    );
};

export default ProductAdd;