import {PageContent} from '../../components';
import {useGetBookCoverTypes, useDeleteBookCoverType} from '../../hooks';


const columns: string[] = [
    'ID', 'Name'
]

const BookCoverType = () => {
    const {data, isSuccess, refetch} = useGetBookCoverTypes(0, 20);
    const {mutate: deleteMenu} = useDeleteBookCoverType(refetch);


    return <PageContent
        title='Book Cover Type'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        totalPage={isSuccess ? data.success.data.totalPages : 0}
        addLink='/book-cover-type/add/'
        deleteItem={deleteMenu}
    />;
};

export default BookCoverType;