import '../../styles/pages/productType.scss';
import React, {FC, useEffect, useState} from 'react';
import {MultiSelect, SubmitButton, TextInput} from '../../components';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {
    useCreateProductType,
    useGetProperties,
    useProductTypes
} from '../../hooks';
import {AvailableProperties, CreateProductTypeBody} from '../../models';

const options = [
    {value: '1', label: 'Option 1'},
    {value: '2', label: 'Option 2'},
    {value: '3', label: 'Option 3'},
    {value: '4', label: 'Option 4'},
    {value: '5', label: 'Option 5'},
    {value: '6', label: 'Option 6'},
    {value: '7', label: 'Option 7'},
    {value: '8', label: 'Option 8'},
    {value: '9', label: 'Option 9'},
    {value: '10', label: 'Option 10'},
    {value: '11', label: 'Option 11'},
    {value: '12', label: 'Option 12'},
    {value: '13', label: 'Option 6'},
    {value: '14', label: 'Option 7'},
    {value: '15', label: 'Option 8'},
    {value: '16', label: 'Option 9'},
    {value: '17', label: 'Option 10'},
    {value: '18', label: 'Option 11'},
    {value: '19', label: 'Option 12'},
];

interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
}

interface Props {
}

const AddProductType: FC<Props> = () => {
    const {data, isSuccess} = useGetProperties(0, 20);
    const {refetch} = useProductTypes(0, 20);
    const {mutate: create} = useCreateProductType(refetch);
    const [properties, setProperties] = useState<AvailableProperties[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: ''
    });

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (isSuccess) {
            let tempArray = []
            let properties_ = data.success.data.content
            for (let i = 0; i < properties_.length; i++) {
                tempArray.push(
                    {
                        productPropertyId: properties_[i].productPropertyId,
                        name: properties_[i].productPropertyLang.name,
                        description: properties_[i].productPropertyLang.description
                    } as AvailableProperties
                )
            }
            setProperties(tempArray)
        }
    }, [isSuccess]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: CreateProductTypeBody = {
            productTypeLangList: [
                {
                    name: formData.nameTR,
                    langCode: 'TR'
                },
                {
                    name: formData.nameRU,
                    langCode: 'RU'
                },
                {
                    name: formData.nameEN,
                    langCode: 'EN'
                }
            ]

        } as CreateProductTypeBody;
        body.productPropertyIdList = selectedOptions;
        create(body);
        setFormData(
            {
                nameTR: '',
                nameRU: '',
                nameEN: ''
            }
        )
        setSelectedOptions([]);
    };

    return (
        <div className='product-type-add-edit'>
            <div className='product-type-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='product-type-add-edit__form' action="" onSubmit={handleSubmit}>

                <div className='product-type-add-edit_table'>
                    <div className='product-type-add-edit_table_left'>
                        <div className="product-type-add-edit__form__item">
                            <TextInput
                                placeholder='TR Name'
                                name='nameTR'
                                onChange={handleChange}
                                value={formData.nameTR}
                            />
                        </div>
                        <div className="product-type-add-edit__form__item">
                            <TextInput
                                placeholder='RU Name'
                                name='nameRU'
                                onChange={handleChange}
                                value={formData.nameRU}
                            />
                        </div>
                        <div className="product-type-add-edit__form__item">
                            <TextInput
                                placeholder='EN Name'
                                name='nameEN'
                                onChange={handleChange}
                                value={formData.nameEN}
                            />
                        </div>
                    </div>
                    <div className='product-type-add-edit_table_right'>
                        <MultiSelect
                            label='Property'
                            options={properties}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                        />
                    </div>
                </div>

                <div className="product-type-add-edit__form__item flex-product-type-form-btn">
                    <div className='product-type-add-edit__form__btn'>
                        <SubmitButton
                            title='Save'
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddProductType;