import '../../styles/pages/product.property.lang.scss';

import React, {FC, useEffect} from 'react';
import {PropertyOfProductType} from '../../models';
import ProductPropertyInput from './ProductPropertyInput';
import {PropertyInput} from './ProductAdd';

interface Props {
    properties: PropertyOfProductType[];
    propertyInputTR: PropertyInput[];
    propertyInputRU: PropertyInput[];
    propertyInputEN: PropertyInput[];
    onChangeTR: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    onChangeRU: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    onChangeEN: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    setPropertyInputTR: (item: PropertyInput[]) => void;
    setPropertyInputRU: (item: PropertyInput[]) => void;
    setPropertyInputEN: (item: PropertyInput[]) => void;
}


const ProductPropertyLang: FC<Props> = ({
                                            properties,
                                            propertyInputTR,
                                            propertyInputRU,
                                            propertyInputEN,
                                            onChangeTR,
                                            onChangeRU,
                                            onChangeEN,
                                            setPropertyInputTR,
                                            setPropertyInputRU,
                                            setPropertyInputEN
                                        }) => {


    useEffect(() => {
        const tempArray: PropertyInput[] = []
        if (properties) {
            for (let i = 0; i < properties.length; i++) {
                tempArray.push(
                    {
                        id: properties[i].productPropertyId,
                        name: properties[i].shortCode,
                        placeholder: properties[i].name,
                        value: ''
                    }
                )
            }
            console.log(tempArray)
            setPropertyInputTR(tempArray);
            setPropertyInputRU(tempArray);
            setPropertyInputEN(tempArray);
        }
    }, [properties])


    return (
        <div>
            <ProductPropertyInput
                label="TR"
                inputs={propertyInputTR}
                onChange={onChangeTR}
            />
            <ProductPropertyInput
                label="RU"
                inputs={propertyInputRU}
                onChange={onChangeRU}
            />
            <ProductPropertyInput
                label="EN"
                inputs={propertyInputEN}
                onChange={onChangeEN}
            />
        </div>
    );
};

export default ProductPropertyLang;