import "../../styles/Layout/Sidebar.scss";

import React, {FC, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import {RxHamburgerMenu} from "react-icons/rx";
import {MdClose} from "react-icons/md";
import {TbCategoryPlus} from "react-icons/tb";
import {TiThMenu} from "react-icons/ti";
import {BsFillHousesFill} from "react-icons/bs";
import {SiSteelseries} from "react-icons/si";
import {FaProductHunt} from "react-icons/fa";

interface Props {
    isOpen: boolean;
    toggleSidebar: (e: any) => void;
}

const Sidebar: FC<Props> = ({isOpen, toggleSidebar}) => {
    let location = useLocation();
    const [active, setActive] = useState<string>('');

    useEffect(() => {
        let path = location.pathname
        if (path.includes('category')) {
            setActive('category')
        } else if (path.includes('book-publishing-brand')) {
            setActive('bookPublishingBrand')
        } else if (path.includes('menu')) {
            setActive('menu')
        } else if (path.includes('publisher-house')) {
            setActive('publisher-house')
        } else if (path.includes('series')) {
            setActive('series')
        } else if (path.includes('product-type')) {
            setActive('productType')
        } else if (path.includes('product')) {
            setActive('product')
        } else if (path.includes('author')) {
            setActive('author')
        } else if (path.includes('property-value')) {
            setActive('propertyValue')
        } else if (path.includes('property')) {
            setActive('property')
        } else if (path.includes('brand')) {
            setActive('brand')
        } else if (path.includes('book-type')) {
            setActive('bookType')
        } else if (path.includes('book-segment')) {
            setActive('bookSegment')
        } else if (path.includes('book-paper-quality')) {
            setActive('bookPaperQuality')
        } else if (path.includes('book-cover-type')) {
            setActive('bookCoverType')
        } else if (path.includes('book-content-language')) {
            setActive('bookContentLanguage')
        }
    }, [location]);


    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <div className="btn">
                <button className="toggle-btn" onClick={toggleSidebar}>
                    {
                        isOpen
                            ?
                            <MdClose/>
                            :
                            <RxHamburgerMenu/>
                    }
                </button>
            </div>
            {
                isOpen
                    ?
                    <ul className='menu-with-text'>
                        <li className={active === 'category' ? 'custom-active' : ''}>
                            <NavLink to="/category/" className='custom-active'>
                                <p>Category</p>
                            </NavLink>
                        </li>
                        <li className={active === 'menu' ? 'custom-active' : ''}>
                            <NavLink to="/menu/">
                                <p>Menu</p>
                            </NavLink>
                        </li>
                        <li className={active === 'brand' ? 'custom-active' : ''}>
                            <NavLink to="/brand/">
                                <p>Brand</p>
                            </NavLink>
                        </li>
                        <li className={active === 'bookType' ? 'custom-active' : ''}>
                            <NavLink to="/book-type/">
                                <p>Book Type</p>
                            </NavLink>
                        </li>
                        <li className={active === 'bookSegment' ? 'custom-active' : ''}>
                            <NavLink to="/book-segment/">
                                <p>Book Segment</p>
                            </NavLink>
                        </li>
                        <li className={active === 'bookPaperQuality' ? 'custom-active' : ''}>
                            <NavLink to="/book-paper-quality/">
                                <p>Book Paper Quality</p>
                            </NavLink>
                        </li>
                        <li className={active === 'bookCoverType' ? 'custom-active' : ''}>
                            <NavLink to="/book-cover-type/">
                                <p>Book Cover Type</p>
                            </NavLink>
                        </li>
                        <li className={active === 'bookContentLanguage' ? 'custom-active' : ''}>
                            <NavLink to="/book-content-language/">
                                <p>Book Content Language</p>
                            </NavLink>
                        </li>
                        <li className={active === 'bookPublishingBrand' ? 'custom-active' : ''}>
                            <NavLink to="/book-publishing-brand/">
                                <p>Book Publishing Brand</p>
                            </NavLink>
                        </li>
                        <li className={active === 'publisher-house' ? 'custom-active' : ''}>
                            <NavLink to="/publisher-house/">
                                <p>Publisher Houses</p>
                            </NavLink>
                        </li>
                        <li className={active === 'series' ? 'custom-active' : ''}>
                            <NavLink to="/series/">
                                <p>Series</p>
                            </NavLink>
                        </li>
                        <li className={active === 'author' ? 'custom-active' : ''}>
                            <NavLink to="/author/">
                                <p>Author</p>
                            </NavLink>
                        </li>
                        <li className={active === 'property' ? 'custom-active' : ''}>
                            <NavLink to="/property/">
                                <p>Property</p>
                            </NavLink>
                        </li>
                        <li className={active === 'productType' ? 'custom-active' : ''}>
                            <NavLink to="/product-type/">
                                <p>Product Type</p>
                            </NavLink>
                        </li>
                        <li className={active === 'product' ? 'custom-active' : ''}>
                            <NavLink to="/product/">
                                <p>Product</p>
                            </NavLink>
                        </li>
                    </ul>
                    :
                    <ul className='menu-with-icon'>
                        <li className={active === 'category' ? 'custom-active' : ''}>
                            <NavLink to="/category/">
                                <TbCategoryPlus/>
                            </NavLink>
                        </li>
                        <li className={active === 'menu' ? 'custom-active' : ''}>
                            <NavLink to="/menu/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'brand' ? 'custom-active' : ''}>
                            <NavLink to="/brand/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'book-type' ? 'custom-active' : ''}>
                            <NavLink to="/book-type/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'book-segment' ? 'custom-active' : ''}>
                            <NavLink to="/book-segment/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'book-paper-quality' ? 'custom-active' : ''}>
                            <NavLink to="/book-paper-quality/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'book-cover-type' ? 'custom-active' : ''}>
                            <NavLink to="/book-cover-type/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'book-content-language' ? 'custom-active' : ''}>
                            <NavLink to="/book-content-language/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'book-publishing-brand' ? 'custom-active' : ''}>
                            <NavLink to="/book-publishing-brand/">
                                <TiThMenu/>
                            </NavLink>
                        </li>
                        <li className={active === 'publisher-house' ? 'custom-active' : ''}>
                            <NavLink to="/publisher-house/">
                                <BsFillHousesFill/>
                            </NavLink>
                        </li>
                        <li className={active === 'series' ? 'custom-active' : ''}>
                            <NavLink to="/series/">
                                <SiSteelseries/>
                            </NavLink>
                        </li>
                        <li className={active === 'author' ? 'custom-active' : ''}>
                            <NavLink to="/author/">
                                <FaProductHunt/>
                            </NavLink>
                        </li>
                        <li className={active === 'property' ? 'custom-active' : ''}>
                            <NavLink to="/property/">
                                <FaProductHunt/>
                            </NavLink>
                        </li>
                        <li className={active === 'productType' ? 'custom-active' : ''}>
                            <NavLink to="/product-type/">
                                <FaProductHunt/>
                            </NavLink>
                        </li>
                        <li className={active === 'product' ? 'custom-active' : ''}>
                            <NavLink to="/product/">
                                <FaProductHunt/>
                            </NavLink>
                        </li>
                    </ul>
            }


        </div>
    );
};

export default Sidebar;