// ProductTable.tsx

import React, {MouseEventHandler, useState} from 'react';
import '../../../styles/components/UI/Table.scss';
import EditButton from '../Button/EditButton';
import DeleteButton from '../Button/DeleteButton';
import {
    AuthorContent,
    BookTypeContent,
    BookSegmentContent,
    BookPaperQualityContent,
    BookCoverTypeContent,
    BookContentLanguageContent,
    BookPublishingBrandContent,
    BrandContent,
    CategoryContent,
    MenuContent,
    ProductContent,
    ProductTypeContent,
    PropertyContent,
    PublisherHouseContent,
    SeriesContent
} from '../../../models';
import CheckBox from '../Input/CheckBox';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

interface ProductTableProps {
    items: (
        CategoryContent |
        MenuContent |
        PublisherHouseContent |
        SeriesContent |
        AuthorContent |
        ProductContent |
        PropertyContent |
        ProductTypeContent |
        BrandContent |
        BookTypeContent |
        BookSegmentContent |
        BookPaperQualityContent |
        BookCoverTypeContent |
        BookContentLanguageContent |
        BookPublishingBrandContent
        )[];
    columns: string[];
    searchValue: string
    setSearchValue: (value: string) => void;
    deleteItem: (id: number) => void;
    totalPage?: number;
    setVisible?: (id: number, isVisible: boolean) => void;
}

const MessageWarn = (message: string, onClickYes: MouseEventHandler | undefined) => {
    confirmAlert({
        message: message,
        buttons: [
            {
                label: 'Evet',
                onClick: onClickYes
            },
            {
                label: 'Hayır'
            }
        ]
    });
};

const Table: React.FC<ProductTableProps> = ({
                                                items,
                                                columns,
                                                searchValue,
                                                setSearchValue,
                                                deleteItem,
                                                totalPage,
                                                setVisible
                                            }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const tableContent = (item: (CategoryContent |
        MenuContent |
        PublisherHouseContent |
        SeriesContent |
        AuthorContent |
        ProductContent |
        PropertyContent |
        ProductTypeContent |
        BrandContent |
        BookTypeContent |
        BookSegmentContent |
        BookPaperQualityContent |
        BookCoverTypeContent |
        BookContentLanguageContent |
        BookPublishingBrandContent
        )
    ) => {
        if ('categoryId' in item) {
            return <tr key={item.categoryId}>
                <td>{item.categoryId}</td>
                <td>{item.categoryLang.name}</td>
                <td>{item.parentId ? item.parentId : 'Main'}</td>
                <td>{item.shortCode}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/category/edit/${item.categoryId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.categoryId} numaralı kategori silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.categoryId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('menuId' in item) {
            return <tr key={item.menuId}>
                <td>{item.menuId}</td>
                <td>{item.menuLang.name}</td>
                <td>{item.shortCode}</td>
                <td>
                    <div className='product-table-center-col'>
                        {
                            setVisible
                                ?
                                <CheckBox
                                    checked={item.isVisible}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setVisible(item.menuId, !item.isVisible)}
                                />
                                :
                                <CheckBox
                                    checked={item.isVisible}
                                />
                        }

                    </div>
                </td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/menu/edit/${item.menuId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.menuId} numaralı menü silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.menuId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('publisherHouseId' in item) {
            return <tr key={item.publisherHouseId}>
                <td>{item.publisherHouseId}</td>
                <td>{item.publisherHouseLang.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/publisher-house/edit/${item.publisherHouseId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.publisherHouseId} numaralı yayınevi silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.publisherHouseId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('authorId' in item) {
            return <tr key={item.authorId}>
                <td>{item.authorId}</td>
                <td>
                    <div className='product-table-center-col'>
                        <img className='product-table-img' src={item.imageUrl} alt=""/>
                    </div>
                </td>
                <td>{item.name}</td>
                <td>{item.surname}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/author/edit/${item.authorId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.authorId} numaralı yazar silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.authorId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('seriesId' in item) {
            return <tr key={item.seriesId}>
                <td>{item.seriesId}</td>
                <td>{item.seriesLang.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/series/edit/${item.seriesId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.seriesId} numaralı seri silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.seriesId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('productTypeId' in item) {
            return <tr key={item.productTypeId}>
                <td>{item.productTypeId}</td>
                <td>{item.productTypeLang.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/product-type/edit/${item.productTypeId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.productTypeId} numaralı ürün türü silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.productTypeId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('productPropertyId' in item) {
            return <tr key={item.productPropertyId}>
                <td>{item.productPropertyId}</td>
                <td>{item.productPropertyLang.name}</td>
                <td>{item.productPropertyLang.description}</td>
                <td>{item.shortCode}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/property/edit/${item.productPropertyId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.productPropertyId} numaralı ürün özelliği silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.productPropertyId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('productId' in item) {
            return <tr key={item.productId}>
                <td>{item.productId}</td>
                <td>
                    <div className='product-table-center-col'>
                        <img className='product-table-img' src={item.imageUrl} alt=""/>
                    </div>
                </td>
                <td>{item.title}</td>
                <td>
                    <div className='product-table-center-col'>
                        <CheckBox
                            checked={item.isPublished}
                        />
                    </div>
                </td>
                <td>
                    <div className='product-table-center-col'>
                        <CheckBox
                            checked={item.isBook}
                        />
                    </div>
                </td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/product/edit/${item.productId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.productId} numaralı ürün silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.productId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('brandId' in item) {
            return <tr key={item.brandId}>
                <td>{item.brandId}</td>
                <td>{item.brandLang.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/brand/edit/${item.brandId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.brandId} numaralı marka silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.brandId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('bookTypeId' in item) {
            return <tr key={item.bookTypeId}>
                <td>{item.bookTypeId}</td>
                <td>{item.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/book-type/edit/${item.bookTypeId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.bookTypeId} numaralı kitap türü silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.bookTypeId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('bookSegmentId' in item) {
            return <tr key={item.bookSegmentId}>
                <td>{item.bookSegmentId}</td>
                <td>{item.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/book-segment/edit/${item.bookSegmentId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.bookSegmentId} numaralı kitap bölümü(segment) silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.bookSegmentId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('bookPaperQualityId' in item) {
            return <tr key={item.bookPaperQualityId}>
                <td>{item.bookPaperQualityId}</td>
                <td>{item.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/book-paper-quality/edit/${item.bookPaperQualityId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.bookPaperQualityId} numaralı kitap kağıt kalite kaydı silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.bookPaperQualityId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('bookCoverTypeId' in item) {
            return <tr key={item.bookCoverTypeId}>
                <td>{item.bookCoverTypeId}</td>
                <td>{item.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/book-cover-type/edit/${item.bookCoverTypeId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.bookCoverTypeId} numaralı kitap cilt türü silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.bookCoverTypeId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('bookContentLanguageId' in item) {
            return <tr key={item.bookContentLanguageId}>
                <td>{item.bookContentLanguageId}</td>
                <td>{item.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/book-content-language/edit/${item.bookContentLanguageId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.bookContentLanguageId} numaralı kitap içerik dil kaydı silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.bookContentLanguageId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        } else if ('bookPublishingBrandId' in item) {
            return <tr key={item.bookPublishingBrandId}>
                <td>{item.bookPublishingBrandId}</td>
                <td>{item.name}</td>
                <td>
                    <div className='btn-table'>
                        <EditButton
                            isLoading={false}
                            url={`/book-publishing-brand/edit/${item.bookPublishingBrandId}`}
                        />
                        <DeleteButton onClick={(e: any) => {
                            MessageWarn(`${item.bookPublishingBrandId} numaralı kitap basım marka kaydı silinecektir. İşleme devam etmek istiyor musunuz?`,
                                () => {
                                    deleteItem(item.bookPublishingBrandId);
                                });

                        }}/>
                    </div>
                </td>
            </tr>
        }
    }

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const createPageCount = (totalPage: number) => {
        const pageNumbers = [];

        for (let i = 1; i <= totalPage; i++) {
            pageNumbers.push(
                <li key={i}>
                    <button onClick={() => handlePageChange(i)}>{i}</button>
                </li>
            );
        }

        return pageNumbers;
    }

    return (
        <div className="product-table-container">
            <input
                type="text"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearch}
                className="search-input"
            />
            <table className="product-table">
                <thead>
                <tr>
                    {
                        columns.map((item, index) => {
                                return <th key={index}>{item}</th>
                            }
                        )
                    }
                    <th>...</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item) => tableContent(item))}
                </tbody>
            </table>
            <div>
                {
                    totalPage
                        ?
                        totalPage > 1
                            ?
                            createPageCount(totalPage)
                            :
                            <></>
                        :
                        <></>

                }
            </div>
        </div>
    );
};

export default Table;
