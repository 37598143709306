import {PageContent} from '../../components';
import {useCategoriesQuery} from '../../hooks';
import {CategoryContent} from '../../models';
import {useCategoryDelete} from '../../hooks/Category/useCategoryMutation';


const columns: string[] = [
    'ID', 'Name', 'Parent', 'Short Code'
]

const Category = () => {
    const {data, isSuccess, refetch} = useCategoriesQuery(0, 20);
    const {mutate: deleteCategory} = useCategoryDelete(refetch);

    return <PageContent
        title='Category'
        columns={columns}
        items={isSuccess ? data.success.data.content as CategoryContent[] : [] as CategoryContent[]}
        addLink='/category/add/'
        deleteItem={deleteCategory}
    />;
};

export default Category;