import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {BookCoverTypes, BookCoverType, ResponseSuccess} from "../../models";
import {bookCoverTypeService} from "../../service";


const useGetBookCoverTypes = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<BookCoverTypes>>({
        queryFn: () => bookCoverTypeService.getBookCoverTypes(page, pageSize),
        queryKey: ['book-cover-types'],
        placeholderData: keepPreviousData
    })
}


const useGetBookCoverType = (id: number) => {
    return useQuery<ResponseSuccess<BookCoverType>>({
        queryFn: () => bookCoverTypeService.getBookCoverTypeByID(id),
        queryKey: ['book-cover-type', id]
    })
}


export {
    useGetBookCoverTypes,
    useGetBookCoverType
};