import {PageContent} from '../../components';
import {useAuthorDelete, useGetAuthors} from '../../hooks';


const columns: string[] = [
    'ID', 'Image', 'Name', 'Surname'
]

const Author = () => {
    const {data, isSuccess, refetch} = useGetAuthors(0, 20);
    const {mutate: deleteMenu} = useAuthorDelete(refetch);


    return <PageContent
        title='Author'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/author/add/'
        deleteItem={deleteMenu}
    />;
};

export default Author;