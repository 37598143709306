import React, {FC, ReactNode, useEffect, useState} from 'react';
import {decodeJwt} from '../utils/decodeJwt';
import {Navigate, useNavigate} from 'react-router-dom';
import {JwtPayload, RefreshToken} from '../models';
import {getRefreshTokenSRV} from '../service';
import {AxiosResponse} from 'axios';
import {ResponseSuccess} from '../models';

interface Props {
    children: ReactNode;
}

const RequireAuth: FC<Props> = ({children}) => {
    const navigate = useNavigate();
    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);

    const checkTokenExpiration = () => {
        const token: JwtPayload | string = decodeJwt();
        if (typeof token !== 'string') {
            setIsTokenExpired(false);
            const expirationTime = new Date(token.exp).getTime();
            console.log(expirationTime)
            const currentTime = new Date().getTime() / 1000;
            // const currentTime = 1711044184;
            if (currentTime > expirationTime) {
                const res = getRefreshTokenSRV();
                res.then((response: AxiosResponse<ResponseSuccess<RefreshToken>>) => {
                    localStorage.setItem('accessToken', response.data.success.data.accessToken);
                    localStorage.setItem('refreshToken', response.data.success.data.refreshToken);
                }).catch((error) => {
                    localStorage.clear();
                    setIsTokenExpired(true);
                });
            }
        } else {
            localStorage.clear();
            setIsTokenExpired(true);
        }
    };

    useEffect(() => {
        checkTokenExpiration();
    }, []);


    if (isTokenExpired) {
        return <Navigate to="/login/"/>
    } else {
        return <div>{children}</div>;
    }
};

export default RequireAuth;