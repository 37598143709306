import {ImageListBody} from "../models/product.models";
import {ProductPropertyValueList} from '../models';
import {ImageListProduct, PropertyInput} from "../pages/Product/ProductAdd";

export function getImageListBody(imageList: ImageListProduct[]): ImageListBody[] {
    let resultArray: ImageListBody[] = [];

    for (let i = 0; i < imageList.length; i++) {
        if (imageList[i].value !== "") {
            resultArray.push(
                {
                    value: imageList[i].value.split(';base64,')[1],
                    extension: imageList[i].extension,
                    imageOrder: imageList[i].imageOrder,
                    isActive: imageList[i].isActive
                } as ImageListBody
            )
        }

    }


    return resultArray;
}


export function getPropertyListBody(propertiesTR: PropertyInput[], propertiesRU: PropertyInput[], propertiesEN: PropertyInput[]): ProductPropertyValueList[] {
    let result: ProductPropertyValueList[] = [];

    for (let i = 0; i < propertiesTR.length; i++) {
        result.push(
            {
                productPropertyId: propertiesTR[i].id,
                productPropertyValueLangList: [
                    {
                        value: propertiesTR[i].value,
                        langCode: "TR"
                    },
                    {
                        value: propertiesRU[i].value,
                        langCode: "RU"
                    },
                    {
                        value: propertiesEN[i].value,
                        langCode: "EN"
                    }
                ]
            }
        )
    }

    return result;
}