import "../../styles/Layout/FormLayout.scss";

import React, {FC, ReactElement} from 'react';
import {IoArrowBackCircle} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';

interface Props {
    child: ReactElement;
}


const FormLayout: FC<Props> = ({child}) => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div className='form-layout'>
            <div className='form-layout__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <div className='form-layout-child'>
                {child}
            </div>
        </div>
    );
};

export default FormLayout;