import React from 'react';
import {useParams} from 'react-router-dom';
import EditAddBrand from './EditAddBrand';
import {useGetBrand} from '../../hooks';

const BrandEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetBrand(Number(id));


    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess
                        ?
                        <EditAddBrand brand={data.success.data} isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );


};

export default BrandEdit;