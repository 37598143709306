import React, {FC, ReactElement} from 'react';
import '../../../styles/components/UI/Button.scss';


interface Props {
    icon?: ReactElement
    title?: string;
    onClick?: (e: any) => void;
}

const DeleteButton: FC<Props> = ({title, icon, onClick}) => {
    return (
        <button className='btn-delete' onClick={onClick}>{title ? title : icon ? icon : 'Delete'}</button>
    );
};

export default DeleteButton;