import {PageContent} from '../../components';
import {useGetBookSegments, useDeleteBookSegment} from '../../hooks';


const columns: string[] = [
    'ID', 'Name'
]

const BookSegment = () => {
    const {data, isSuccess, refetch} = useGetBookSegments(0, 20);
    const {mutate: deleteMenu} = useDeleteBookSegment(refetch);


    return <PageContent
        title='Book Segment'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        totalPage={isSuccess ? data.success.data.totalPages : 0}
        addLink='/book-segment/add/'
        deleteItem={deleteMenu}
    />;
};

export default BookSegment;