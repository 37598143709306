import React from 'react';
import {useParams} from 'react-router-dom';
import EditAddBookType from './EditAddBookType';
import {useGetBookType} from '../../hooks';

const BookTypeEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetBookType(Number(id));


    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess
                        ?
                        <EditAddBookType bookType={data.success.data} isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );


};

export default BookTypeEdit;