import {client} from "../api";
import {PublisherHouse, ResponseError, ResponseSuccess} from "../models";
import {PublisherHouseBody, PublisherHouseById} from "../models/publisherHouse.model";


export async function getPublisherHouse(id: number): Promise<ResponseSuccess<PublisherHouseById> | ResponseError | any> {
    return (await client.get<ResponseSuccess<PublisherHouseById> | ResponseError>(`/api/v1/admin/publisher-houses/${id}`)).data;
}


export async function getPublisherHouses(page: number, pageSize: number): Promise<ResponseSuccess<PublisherHouse> | ResponseError | any> {
    return (await client.get<ResponseSuccess<PublisherHouse> | ResponseError>(`/api/v1/admin/publisher-houses?page=${page}&size=${pageSize}`)).data;
}


export async function getFilterPublisherHouses(name: string, page: number, pageSize: number): Promise<ResponseSuccess<PublisherHouse> | ResponseError | any> {
    return await client.get<ResponseSuccess<PublisherHouse> | ResponseError>(`/api/v1/admin/publisher-houses/browse?name=${name}&page=${page}&size=${pageSize}`);
}


export async function createPublisherHouse(body: PublisherHouseBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/publisher-houses`, body)).data;
}


export async function updatePublisherHouse(body: PublisherHouseBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/publisher-houses`, body)).data;
}


export async function deletePublisherHouse(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/publisher-houses/${id}`)).data;
}