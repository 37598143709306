import '../../styles/pages/brand.scss';
import React, {FC, useEffect, useState} from 'react';
import {SubmitButton, TextInput} from '../../components';
import {Brand, BrandBody} from '../../models';
import {useNavigate} from 'react-router-dom';
import {useCreateBrand, useGetBrands, useUpdateBrand} from '../../hooks';
import FormLayout from '../../components/Layout/FormLayout';


interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
}

interface Props {
    brand?: Brand;
    isEdit: boolean;
}

const EditAddBrand: FC<Props> = ({isEdit, brand}) => {
    const {refetch} = useGetBrands(0, 20);
    const {mutate: create} = useCreateBrand(refetch);
    const {mutate: update} = useUpdateBrand(refetch);

    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: '',
    });
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (isEdit) {
            if (brand) {
                if ('brandId' in brand) {
                    for (let i = 0; i < brand.brandLangList.length; i++) {
                        if (brand.brandLangList[i].langCode === 'TR') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameTR: brand.brandLangList[i].name,
                            }));
                        } else if (brand.brandLangList[i].langCode === 'RU') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameRU: brand.brandLangList[i].name,
                            }));
                        } else if (brand.brandLangList[i].langCode === 'EN') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameEN: brand.brandLangList[i].name,
                            }));
                        }
                    }
                }
            }
        }
    }, [brand]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: BrandBody = {
            brandLangList: [
                {
                    name: formData.nameTR,
                    langCode: 'TR'
                },
                {
                    name: formData.nameRU,
                    langCode: 'RU'
                },
                {
                    name: formData.nameEN,
                    langCode: 'EN'
                }
            ]

        } as BrandBody;

        if (isEdit) {
            let langList = []
            if (brand) {
                for (let i = 0; i < brand.brandLangList.length; i++) {
                    if (brand.brandLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                brandLangId: brand.brandLangList[i].brandLangId,
                                name: formData.nameTR,
                                langCode: 'TR'
                            }
                        );
                    } else if (brand.brandLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                brandLangId: brand.brandLangList[i].brandLangId,
                                name: formData.nameRU,
                                langCode: 'RU'
                            }
                        );
                    } else if (brand.brandLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                brandLangId: brand.brandLangList[i].brandLangId,
                                name: formData.nameEN,
                                langCode: 'EN'
                            }
                        );
                    }
                }
            }
            body.brandLangList = langList
            body.brandId = brand?.brandId;
            update(body);

        } else {
            create(body);
            setFormData({
                nameTR: '',
                nameRU: '',
                nameEN: ''
            });
        }

    };

    return (
        <FormLayout child={
            <form className='brand-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className="brand-add-edit__form__item">
                    <TextInput
                        placeholder='TR Name'
                        name='nameTR'
                        onChange={handleChange}
                        value={formData.nameTR}
                    />
                </div>
                <div className="brand-add-edit__form__item">
                    <TextInput
                        placeholder='RU Name'
                        name='nameRU'
                        onChange={handleChange}
                        value={formData.nameRU}
                    />
                </div>
                <div className="brand-add-edit__form__item">
                    <TextInput
                        placeholder='EN Name'
                        name='nameEN'
                        onChange={handleChange}
                        value={formData.nameEN}
                    />
                </div>
                <div className="brand-add-edit__form__item flex-brand-form-btn">
                    <div className='brand-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        }/>
    );
};

export default EditAddBrand;