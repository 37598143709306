import React from 'react';
import "../../styles/Layout/Content.scss";
import {Outlet} from 'react-router-dom';


const Content = () => {
    return (
        <div className="content">
            <Outlet/>
        </div>
    );
};

export default Content;