import React, {ChangeEvent} from 'react';
import '../../../styles/components/UI/InputText.scss';

interface InputProps {
    placeholder?: string;
    name?: string;
    type?: string
    value: string;
    disabled?: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<InputProps> = ({placeholder, value, onChange, name, type, disabled}) => {
    return (
        <input
            disabled={disabled ? disabled : false}
            type={type ? type : 'text'}
            className="custom-input"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
        />
    );
};

export default TextInput;