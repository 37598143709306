import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {BookPublishingBrands, BookPublishingBrand, ResponseSuccess} from "../../models";
import {bookPublishingBrandService} from "../../service";


const useGetBookPublishingBrands = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<BookPublishingBrands>>({
        queryFn: () => bookPublishingBrandService.getBookPublishingBrands(page, pageSize),
        queryKey: ['book-publishing-brands'],
        placeholderData: keepPreviousData
    })
}


const useGetBookPublishingBrand = (id: number) => {
    return useQuery<ResponseSuccess<BookPublishingBrand>>({
        queryFn: () => bookPublishingBrandService.getBookPublishingBrandByID(id),
        queryKey: ['book-publishing-brand', id]
    })
}


export {
    useGetBookPublishingBrands,
    useGetBookPublishingBrand
};