import '../../styles/pages/book.scss';

import React, {FC, useState} from 'react';
import {DateTime, Dropdown, MultiSelect, TextInput} from '../../components';
import {useGetPublisherHouses} from '../../hooks/PublisherHouse/usePublisherHouseQueries';
import {useGetBookTypes} from '../../hooks';
import {useGetBookSegments} from '../../hooks';
import {useGetBookPaperQualities} from '../../hooks';
import {useGetBookCoverTypes} from '../../hooks';
import {useGetBookContentLanguages} from '../../hooks';
import {useGetBookPublishingBrands} from '../../hooks';
import {useGetAuthors} from '../../hooks';
import {useGetSeries} from '../../hooks/Series/useSeriesQueries';
import {BookInputType} from './ProductAdd';


interface Props {
    bookInput: BookInputType;
    onChangeBookInput: (e: React.ChangeEvent<HTMLInputElement>) => void
    authors: number[];
    setAuthors: (item: number[]) => void;
    series: number[];
    setSeries: (item: number[]) => void;
    publisherHouse: string;
    setPublisherHouse: (item: string) => void;
    bookType: string;
    setBookType: (item: string) => void;
    bookSegment: string;
    setBookSegment: (item: string) => void;
    bookPaperQuality: string;
    setBookPaperQuality: (item: string) => void;
    bookCoverType: string;
    setBookCoverType: (item: string) => void;
    bookContentLanguage: string;
    setBookContentLanguage: (item: string) => void;
    bookPublishingBrand: string;
    setBookPublishingBrand: (item: string) => void;
}

const Book: FC<Props> = ({
                             bookInput,
                             onChangeBookInput,
                             authors,
                             setAuthors,
                             series,
                             setSeries,
                             publisherHouse,
                             setPublisherHouse,
                             bookType,
                             setBookType,
                             bookSegment,
                             setBookSegment,
                             bookPaperQuality,
                             setBookPaperQuality,
                             bookCoverType,
                             setBookCoverType,
                             bookContentLanguage,
                             setBookContentLanguage,
                             bookPublishingBrand,
                             setBookPublishingBrand,
                         }) => {

    const {data, isSuccess} = useGetPublisherHouses(0, 20);
    const {data: bookTypeData, isSuccess: isSuccessBookType} = useGetBookTypes(0, 20);
    const {data: bookSegmentData, isSuccess: isSuccessBookSegment} = useGetBookSegments(0, 20);
    const {data: bookPaperQualityData, isSuccess: isSuccessBookPaperQuality} = useGetBookPaperQualities(0, 20);
    const {data: bookCoverTypeData, isSuccess: isSuccessBookCoverType} = useGetBookCoverTypes(0, 20);
    const {data: bookContentLanguageData, isSuccess: isSuccessBookContentLanguage} = useGetBookContentLanguages(0, 20);
    const {data: bookPublishingBrandData, isSuccess: isSuccessBookPublishingBrand} = useGetBookPublishingBrands(0, 20);
    const {data: author, isSuccess: isSuccessAuthor} = useGetAuthors(0, 20);
    const {data: seriesData, isSuccess: isSuccessSeries} = useGetSeries(0, 20);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [selectedBookTypeOption, setSelectedBookTypeOption] = useState<string>('');
    const [selectedBookSegmentOption, setSelectedBookSegmentOption] = useState<string>('');
    const [selectedBookPaperQualityOption, setSelectedBookPaperQualityOption] = useState<string>('');
    const [selectedBookCoverTypeOption, setSelectedBookCoverTypeOption] = useState<string>('');
    const [selectedBookContentLanguageOption, setSelectedBookContentLanguageOption] = useState<string>('');
    const [selectedBookPublishingBrandOption, setSelectedBookPublishingBrandOption] = useState<string>('');

    return (
        <ul className='book'>
            <li className=''>
                <TextInput
                    placeholder='ISBN'
                    name='isbn'
                    onChange={onChangeBookInput}
                    value={bookInput.isbn}
                />
            </li>
            <li className=''>
                <TextInput
                    type='number'
                    placeholder='Page Count'
                    name='pageCount'
                    onChange={onChangeBookInput}
                    value={bookInput.pageCount === 0 ? '' : bookInput.pageCount.toString()}
                />
                <TextInput
                    type='number'
                    placeholder='Weight'
                    name='weight'
                    onChange={onChangeBookInput}
                    value={bookInput.weight === 0 ? '' : bookInput.weight.toString()}
                />
                <TextInput
                    type='number'
                    placeholder='Age Limitation'
                    name='ageLimitation'
                    onChange={onChangeBookInput}
                    value={bookInput.ageLimitation}
                />
            </li>
            <li className=''>
                <TextInput
                    placeholder='Book Size'
                    name='bookSize'
                    onChange={onChangeBookInput}
                    value={bookInput.bookSize}
                />
                <DateTime
                    title='Edition Date'
                    name='editionDate'
                    onChange={onChangeBookInput}
                    value={bookInput.editionDate}
                />
            </li>
            <li className=''>
                <TextInput
                    placeholder='Publisher Code Of Product'
                    name='publisherCodeOfProduct'
                    onChange={onChangeBookInput}
                    value={bookInput.publisherCodeOfProduct}
                />
                <TextInput
                    placeholder='Revision Number'
                    name='revisionNumber'
                    onChange={onChangeBookInput}
                    value={bookInput.revisionNumber}
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setPublisherHouse}
                    options={isSuccess ? data.success.data.content : []}
                    selectedOption={selectedOption}
                    selectedValue={publisherHouse}
                    setSelectedOption={setSelectedOption}
                    title='Select Publisher House'
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setBookType}
                    options={isSuccessBookType ? bookTypeData.success.data.content : []}
                    selectedOption={selectedBookTypeOption}
                    selectedValue={bookType}
                    setSelectedOption={setSelectedBookTypeOption}
                    title='Select Book Type'
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setBookSegment}
                    options={isSuccessBookSegment ? bookSegmentData.success.data.content : []}
                    selectedOption={selectedBookSegmentOption}
                    selectedValue={bookSegment}
                    setSelectedOption={setSelectedBookSegmentOption}
                    title='Select Book Segment'
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setBookPaperQuality}
                    options={isSuccessBookPaperQuality ? bookPaperQualityData.success.data.content : []}
                    selectedOption={selectedBookPaperQualityOption}
                    selectedValue={bookPaperQuality}
                    setSelectedOption={setSelectedBookPaperQualityOption}
                    title='Select Book Paper Quality'
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setBookCoverType}
                    options={isSuccessBookCoverType ? bookCoverTypeData.success.data.content : []}
                    selectedOption={selectedBookCoverTypeOption}
                    selectedValue={bookCoverType}
                    setSelectedOption={setSelectedBookCoverTypeOption}
                    title='Select Book Cover Type'
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setBookContentLanguage}
                    options={isSuccessBookContentLanguage ? bookContentLanguageData.success.data.content : []}
                    selectedOption={selectedBookContentLanguageOption}
                    selectedValue={bookContentLanguage}
                    setSelectedOption={setSelectedBookContentLanguageOption}
                    title='Select Book Content Language'
                />
            </li>
            <li className=''>
                <Dropdown
                    onSelect={setBookPublishingBrand}
                    options={isSuccessBookPublishingBrand ? bookPublishingBrandData.success.data.content : []}
                    selectedOption={selectedBookPublishingBrandOption}
                    selectedValue={bookPublishingBrand}
                    setSelectedOption={setSelectedBookPublishingBrandOption}
                    title='Select Book Publishing Brand'
                />
            </li>
            <li className='book-multi-select-li'>
                <MultiSelect
                    label='Authors'
                    options={isSuccessAuthor ? author.success.data.content : []}
                    selectedOptions={authors}
                    setSelectedOptions={setAuthors}
                />
                <MultiSelect
                    label='Series'
                    options={isSuccessSeries ? seriesData.success.data.content : []}
                    selectedOptions={series}
                    setSelectedOptions={setSeries}
                />
            </li>
        </ul>
    );
};

export default Book;