import {useQuery} from "@tanstack/react-query"
import {getCategoryByIdSRV} from "../../service"
import {Category, ResponseSuccess} from "../../models";


const useCategoryQuery = (id: number) => {

    return useQuery<ResponseSuccess<Category>>({
        queryFn: () => getCategoryByIdSRV(id),
        queryKey: ['category', id]
    })
}

export default useCategoryQuery;