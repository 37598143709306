import {create} from "zustand";
import {AxiosError, AxiosResponse} from "axios";
import {NavigateFunction} from "react-router-dom";
import {Auth, RefreshToken, ResponseError, ResponseSuccess, UserState} from "../models";
import {getRefreshTokenSRV, loginSrv} from "../service";
import {getEmail} from "../utils/getEmail";


const useAuthStore = create<UserState>()((set, get) => ({
    email: getEmail() || '',
    accessToken: localStorage.getItem("accessToken") || '',
    refreshToken: localStorage.getItem("refreshToken") || '',
    error: '',
    isLoading: false,
    login: (email: string, password: string, navigate: NavigateFunction) => {

        set({
            isLoading: true
        });

        const res = loginSrv(email, password);
        res.then((r: AxiosResponse<ResponseSuccess<Auth>>) => {
            set({
                accessToken: r.data.success.data.accessToken,
                refreshToken: r.data.success.data.refreshToken
            });
            localStorage.setItem('accessToken', r.data.success.data.accessToken);
            localStorage.setItem('refreshToken', r.data.success.data.refreshToken);
            set({
                isLoading: false
            });
            navigate('/');
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
                if (error.response.status === 401) {
                }
            }
        });
        set({
            isLoading: false
        });
    },
    getRefreshToken: (navigate: NavigateFunction) => {
        set({
            isLoading: true
        })

        const res = getRefreshTokenSRV();
        res.then((r: AxiosResponse<ResponseSuccess<RefreshToken>>) => {
            set({
                accessToken: r.data.success.data.accessToken,
                refreshToken: r.data.success.data.refreshToken
            });
            localStorage.setItem('accessToken', r.data.success.data.accessToken);
            localStorage.setItem('refreshToken', r.data.success.data.refreshToken);
            set({
                isLoading: false
            })
            navigate('/')
        }).catch((error: AxiosError<ResponseError>) => {
            if (error.response) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                if (error.response.status === 401) {
                }
            }

        })
        set({
            isLoading: false
        })
    },
    logout: (navigate: NavigateFunction) => {
        localStorage.clear()
        navigate('/login/');
    }
}))

export default useAuthStore;