import {ResponseError} from "../models";
import {AxiosError} from "axios";
import toast from "react-hot-toast";

const isValidationError = (error: any): error is ResponseError => {
    return (
        error &&
        typeof error === 'object' &&
        'rid' in error &&
        'date' in error &&
        'message' in error &&
        'error' in error &&
        'validationErrors' in error.error
    );
};

const isGenericError = (error: any): error is ResponseError => {
    return (
        error &&
        typeof error === 'object' &&
        'rid' in error &&
        'date' in error &&
        'message' in error &&
        'error' in error &&
        'type' in error.error &&
        !('validationErrors' in error.error)
    );
};

const formatValidationErrorMessage = (error: ResponseError): string => {
    const baseMessage = error.message;

    const validationMessages: string[] = [];
    for (const [field, messages] of Object.entries(error.error.validationErrors)) {
        const formattedField = field.replace(/\./g, ' ');
        const combinedMessages = messages.join(', ');
        validationMessages.push(`${formattedField} : ${combinedMessages}!`);
    }

    return [baseMessage, '\n', ...validationMessages].join('\n');
};

const handleErrorResponse = (axiosError: AxiosError) => {

    const error = axiosError.response?.data;

    if (isGenericError(error)) {
        toast.error(error.message, {
            duration: 4200
        });
    } else if (isValidationError(error)) {
        const message = formatValidationErrorMessage(error);
        toast.error(message, {
            duration: 4200
        });
    } else {
        toast.error('An unexpected error occurred.', {
            duration: 4200
        });
    }

}

export {
    handleErrorResponse
}