import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetMenu} from '../../hooks/Menu/useMenuQueries';
import EditAddAuthor from './EditAddAuthor';
import {useGetAuthor} from '../../hooks';

const AuthorEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetAuthor(Number(id));

    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess
                        ?
                        <EditAddAuthor author={data.success.data} isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );


};

export default AuthorEdit;