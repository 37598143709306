import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {Brands, Brand, ResponseSuccess} from "../../models";
import {brandService} from "../../service";


const useGetBrands = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<Brands>>({
        queryFn: () => brandService.getBrands(page, pageSize),
        queryKey: ['brands'],
        placeholderData: keepPreviousData
    })
}


const useGetBrand = (id: number) => {
    return useQuery<ResponseSuccess<Brand>>({
        queryFn: () => brandService.getBrandByID(id),
        queryKey: ['brand', id]
    })
}


export {
    useGetBrands,
    useGetBrand
};