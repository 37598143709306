import {useGetProductTypeProperty} from '../../hooks';
import '../../styles/pages/product.property.scss';

import React, {FC} from 'react';
import ProductPropertyLang from './ProductPropertyLang';
import {PropertyInput} from './ProductAdd';

interface Props {
    productTypeId: number;
    propertyInputTR: PropertyInput[];
    propertyInputRU: PropertyInput[];
    propertyInputEN: PropertyInput[];
    onChangeTR: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    onChangeRU: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    onChangeEN: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    setPropertyInputTR: (item: PropertyInput[]) => void;
    setPropertyInputRU: (item: PropertyInput[]) => void;
    setPropertyInputEN: (item: PropertyInput[]) => void;
}


const ProductProperty: FC<Props> = ({
                                        productTypeId,
                                        propertyInputTR,
                                        propertyInputRU,
                                        propertyInputEN,
                                        onChangeTR,
                                        onChangeRU,
                                        onChangeEN,
                                        setPropertyInputTR,
                                        setPropertyInputRU,
                                        setPropertyInputEN
                                    }) => {
    const {data, isSuccess} = useGetProductTypeProperty(productTypeId);


    return (
        <div className='product-property'>
            <p className='product-property-title'>Properties</p>
            <div className='product-property-lang-input'>
                <ProductPropertyLang
                    properties={isSuccess ? data.success.data : []}
                    propertyInputEN={propertyInputEN}
                    propertyInputRU={propertyInputRU}
                    propertyInputTR={propertyInputTR}
                    onChangeEN={onChangeEN}
                    onChangeRU={onChangeRU}
                    onChangeTR={onChangeTR}
                    setPropertyInputEN={setPropertyInputEN}
                    setPropertyInputRU={setPropertyInputRU}
                    setPropertyInputTR={setPropertyInputTR}
                />
            </div>

        </div>
    )
};

export default ProductProperty;