import '../../styles/pages/general.input.product.scss';
import React, {FC} from 'react';
import {CheckBox, TextInput} from '../../components';
import {GeneralInputType} from './ProductAdd';

interface Props {
    generalInputItem: GeneralInputType;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const GeneralInput: FC<Props> = ({generalInputItem, onChange}) => {


    return (
        <ul className='general-input-product'>
            <li className='general-input-product__first_li'>
                <TextInput
                    placeholder='Barcode'
                    name='barcode'
                    onChange={onChange}
                    value={generalInputItem.barcode}
                />
                <div>
                    <CheckBox
                        label='Publish'
                        name='isPublished'
                        value='isPublished'
                        checked={generalInputItem.isPublished}
                        onChange={onChange}
                    />
                </div>
            </li>
            <li className='general-input-product__second_li'>
                <TextInput
                    type='number'
                    placeholder='Price'
                    name='price'
                    onChange={onChange}
                    value={generalInputItem.price === 0 ? '' : generalInputItem.price.toString()}
                />
                <TextInput
                    type='number'
                    placeholder='Available Stock'
                    name='availableStock'
                    onChange={onChange}
                    value={generalInputItem.availableStock === 0 ? '' : generalInputItem.availableStock.toString()}
                />
            </li>
        </ul>
    );
};

export default GeneralInput;