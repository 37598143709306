import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BrandBody} from "../../models";
import {brandService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBrand = (refetch: any) => {

    return useMutation({
        mutationKey: ['create brand'],
        mutationFn: (body: BrandBody) => brandService.createBrand(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateBrand = (refetch: any) => {

    return useMutation({
        mutationKey: ['update brand'],
        mutationFn: (body: BrandBody) => brandService.updateBrand(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBrand = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete brand'],
        mutationFn: (id: number) => brandService.deleteBrand(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBrand,
    useUpdateBrand,
    useDeleteBrand
}