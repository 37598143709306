import React, {ChangeEvent, FC} from 'react';
import '../../../styles/components/UI/DateTime.scss';


interface Props {
    title: string;
    name: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}


const DateTime: FC<Props> = ({name, value, title, onChange}) => {
    return (
        <div className='date-time'>
            <div className='date-time-title'>
                <p>{title} :</p>
            </div>
            <input className="custom-input" type="date" id={name} name={name} value={value} onChange={onChange}/>
        </div>
    );
};

export default DateTime;