import {PageContent} from '../../components';
import {useDeleteProductType, useProductTypes} from '../../hooks';


const columns: string[] = [
    'ID', 'Name'
]

const ProductType = () => {
    const {data, isSuccess, refetch} = useProductTypes(0, 20);
    const {mutate: deleteMenu} = useDeleteProductType(refetch);


    return <PageContent
        title='Product Type'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/product-type/add/'
        deleteItem={deleteMenu}
    />;
};

export default ProductType;