import React from 'react';
import {useParams} from 'react-router-dom';
import EditAddSeries from './EditAddSeries';
import {useGetSeriesById} from '../../hooks/Series/useSeriesQueries';

const SeriesEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetSeriesById(Number(id));


    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess
                        ?
                        <EditAddSeries series={data.success.data} isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );


};

export default SeriesEdit;