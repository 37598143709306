import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BookPaperQualityBody} from "../../models";
import {bookPaperQualityService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBookPaperQuality = (refetch: any) => {

    return useMutation({
        mutationKey: ['create book paper quality'],
        mutationFn: (body: BookPaperQualityBody) => bookPaperQualityService.createBookPaperQuality(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateBookPaperQuality = (refetch: any) => {

    return useMutation({
        mutationKey: ['update book paper quality'],
        mutationFn: (body: BookPaperQualityBody) => bookPaperQualityService.updateBookPaperQuality(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBookPaperQuality = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete book paper quality'],
        mutationFn: (id: number) => bookPaperQualityService.deleteBookPaperQuality(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBookPaperQuality,
    useUpdateBookPaperQuality,
    useDeleteBookPaperQuality
}