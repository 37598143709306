import {useMutation} from "@tanstack/react-query";
import {CreateProductBody} from "../../models";
import {productService} from "../../service";
import toast from "react-hot-toast";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";


const useProductCreate = (refetch: any) => {

    return useMutation({
        mutationKey: ['create product'],
        mutationFn: (body: CreateProductBody) => productService.createProduct(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useProductUpdate = (refetch: any) => {

    return useMutation({
        mutationKey: ['update product'],
        mutationFn: (body: CreateProductBody) => productService.updateProduct(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useProductDelete = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete product'],
        mutationFn: (id: number) => productService.deleteProduct(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useProductCreate,
    useProductUpdate,
    useProductDelete
}