// Header.tsx

import React, {useEffect, useState} from 'react';
import '../../styles/Layout/Header.scss';
import useAuthStore from '../../store/useAuth';
import {useNavigate} from 'react-router-dom';

interface HeaderProps {
    //   username: string;
}

const Header: React.FC<HeaderProps> = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [emailLocal, setEmailLocal] = useState<string>('');
    const [
        email,
        logout
    ] = useAuthStore((state) => [
        state.email,
        state.logout
    ])
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        setEmailLocal(email)
    }, [email !== ''])

    return (
        <header className="header">
            <div className="logo">
                <img alt='logo' src={process.env.PUBLIC_URL + "/images/logo.png"}/>
            </div>
            <div className="account">
                <div className="username toggle-menu-btn" onClick={toggleMenu}>{emailLocal}</div>
                {isMenuOpen && (
                    <ul className="menu">
                        <li>Profile</li>
                        <li>Settings</li>
                        <li onClick={() => logout(navigate)}>Logout</li>
                    </ul>
                )}
            </div>
        </header>
    );
};

export default Header;
