import React from 'react';
import EditAddCategory from './EditAddCategory';
import {useParams} from 'react-router-dom';
import {useCategoriesExcludeQuery, useCategoryQuery} from '../../hooks';

const CategoryEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useCategoryQuery(Number(id));
    const {data: excludeCategories, isSuccess: isSuccessExclude} = useCategoriesExcludeQuery(Number(id));

    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess && isSuccessExclude
                        ?
                        <EditAddCategory categoriesExclude={excludeCategories.success.data} category={data.success.data}
                                         isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );


};

export default CategoryEdit;