import '../../styles/pages/product.property.input.scss';

import React, {FC} from 'react';
import {TextInput} from '../../components';
import {PropertyInput} from './ProductAdd';

interface Props {
    label: string;
    inputs: PropertyInput[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}


const ProductPropertyInput: FC<Props> = ({label, inputs, onChange}) => {

    return (
        <div className='product-property-input'>
            <p className='product-property-input__label'>{label}</p>
            <ul className='product-property-input__items'>
                {
                    inputs.map((item, index) => {
                        return <li key={index}>
                            <TextInput
                                key={index}
                                placeholder={item.placeholder}
                                name={item.name}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, index)}
                                value={item.value}
                            />
                        </li>
                    })
                }
            </ul>
        </div>
    );
};

export default ProductPropertyInput;