import '../../../styles/components/UI/RadioButton.scss';

import React, {FC} from 'react';

interface Props {
    label: string;
    value: string;
    name: string
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: FC<Props> = ({label, value, name, checked, onChange}) => {
    return (
        <label className="container-radio-button">{label}
            <input
                type="radio"
                value={value}
                checked={checked}
                name={name}
                onChange={onChange}
            />
            <span className="checkmark-radio-button"></span>
        </label>
    );
};

export default RadioButton;