import '../../../styles/components/UI/InputFile.scss';

import React, {FC, ReactElement} from 'react';

interface Props {
    icon?: ReactElement;
    title?: string;
    accept?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const InputFile: FC<Props> = ({icon, onChange, title, accept}) => {

    return (
        <div>
            <input type="file" id="file" accept={accept} className="input-file" onChange={onChange}/>
            <label htmlFor="file" className="custom-file-label">
                <p>{title}</p>
                <div>
                    {icon}
                </div>
            </label>
        </div>
    );
};

export default InputFile;