import {decodeJwt} from "./decodeJwt";

export function getEmail() {
    try {
        const decodeToken = decodeJwt();
        if (typeof decodeToken === 'string') {
            return ''
        }
        return decodeToken.email
    } catch {
        return ''
    }
}