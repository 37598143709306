import {client} from "../api";
import {CreateProductBody, Product, Products, ResponseError, ResponseSuccess} from "../models";


class ProductService {

    async getProducts(page: number, pageSize: number): Promise<ResponseSuccess<Products> | ResponseError | any> {
        return (await client.get<ResponseSuccess<Products> | ResponseError>(`/api/v1/admin/products/table-items/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getProductByID(id: number): Promise<ResponseSuccess<Product> | ResponseError | any> {
        return (await client.get<ResponseSuccess<Product> | ResponseError>(`/api/v1/admin/products/${id}`)).data;
    }

    async createProduct(body: CreateProductBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/products`, body)).data;
    }

    async updateProduct(body: CreateProductBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/products`, body)).data;
    }

    async deleteProduct(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/products/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductService();