import {PageContent} from '../../components';
import {useGetBrands, useDeleteBrand} from '../../hooks';


const columns: string[] = [
    'ID', 'Name'
]

const Brand = () => {
    const {data, isSuccess, refetch} = useGetBrands(0, 20);
    const {mutate: deleteMenu} = useDeleteBrand(refetch);


    return <PageContent
        title='Brand'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        totalPage={isSuccess ? data.success.data.totalPages : 0}
        addLink='/brand/add/'
        deleteItem={deleteMenu}
    />;
};

export default Brand;