import {useMutation} from "@tanstack/react-query";
import {MenuBody, MenuSetVisibleBody} from "../../models";
import {createMenu, deleteMenu, setVisibleMenu, updateMenu} from "../../service";
import toast from "react-hot-toast";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useMenuCreate = (refetch: any) => {

    return useMutation({
        mutationKey: ['create menu'],
        mutationFn: (body: MenuBody) => createMenu(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useMenuUpdate = (refetch: any) => {

    return useMutation({
        mutationKey: ['update menu'],
        mutationFn: (body: MenuBody) => updateMenu(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useMenuSetVisible = (refetch: any) => {

    return useMutation({
        mutationKey: ['set visible menu'],
        mutationFn: (body: MenuSetVisibleBody) => setVisibleMenu(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Set visible was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useMenuDelete = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete menu'],
        mutationFn: (id: number) => deleteMenu(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useMenuCreate,
    useMenuUpdate,
    useMenuDelete,
    useMenuSetVisible
}