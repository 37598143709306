import '../../../styles/components/UI/CheckBox.scss';

import React, {FC} from 'react';

interface Props {
    label?: string;
    value?: string;
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


const CheckBox: FC<Props> = ({label, value, checked, name, disabled, onChange}) => {
    return (
        <label className="container-checkbox">{label}
            <input
                disabled={disabled ? disabled : false}
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span className="checkmark-checkbox"></span>
        </label>
    );
};

export default CheckBox;