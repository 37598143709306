import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BookTypeBody} from "../../models";
import {bookTypeService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBookType = (refetch: any) => {

    return useMutation({
        mutationKey: ['create book type'],
        mutationFn: (body: BookTypeBody) => bookTypeService.createBookType(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }

    });
}

const useUpdateBookType = (refetch: any) => {

    return useMutation({
        mutationKey: ['update book type'],
        mutationFn: (body: BookTypeBody) => bookTypeService.updateBookType(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBookType = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete book type'],
        mutationFn: (id: number) => bookTypeService.deleteBookType(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBookType,
    useUpdateBookType,
    useDeleteBookType
}