import '../../styles/pages/product.lang.list.scss';
import React, {FC} from 'react';
import {TextInput} from '../../components';
import {ProductLangListInputs} from './ProductAdd';
import {CKEditor, CKEditorEventPayload} from 'ckeditor4-react';

interface Props {
    lang: string;
    langList: ProductLangListInputs;
    onChange: (e: React.ChangeEvent<HTMLInputElement> | CKEditorEventPayload<"change">) => void;
}


const ProductLangList: FC<Props> = ({lang, langList, onChange}) => {
    return (
        <div>
            <p className="product-lang-list__label">{lang}</p>
            <ul className="product-lang-list_input">
                <li>
                    <TextInput
                        placeholder='Title'
                        name='title'
                        onChange={onChange}
                        value={langList.title}
                    />
                </li>
                <li className='product-lang-list_CKEditor'>
                    <CKEditor
                        editorUrl="https://cdn.ckeditor.com/4.22.1/standard/ckeditor.js"
                        config={{
                            versionCheck:false
                        }}
                        initData={langList.description}
                        style={{borderColor: '#e96b2d', width: '100%'}}
                        type="classic"
                        onChange={onChange}
                    />

                </li>
            </ul>
        </div>
    );
};

export default ProductLangList;