import {useQuery} from "@tanstack/react-query";
import {Author, Authors, ResponseSuccess} from "../../models";
import {getAuthor, getAuthors} from "../../service";


const useGetAuthors = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<Authors>>({
        queryFn: () => getAuthors(page, pageSize),
        queryKey: ['authors']
    })
}


const useGetAuthor = (id: number) => {
    return useQuery<ResponseSuccess<Author>>({
        queryFn: () => getAuthor(id),
        queryKey: ['author', id]
    })
}

export {
    useGetAuthors,
    useGetAuthor
};