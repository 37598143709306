import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BookContentLanguageBody} from "../../models";
import {bookContentLanguageService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBookContentLanguage = (refetch: any) => {

    return useMutation({
        mutationKey: ['create book content language'],
        mutationFn: (body: BookContentLanguageBody) => bookContentLanguageService.createBookContentLanguage(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateBookContentLanguage = (refetch: any) => {

    return useMutation({
        mutationKey: ['update book content language'],
        mutationFn: (body: BookContentLanguageBody) => bookContentLanguageService.updateBookContentLanguage(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBookContentLanguage = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete book content language'],
        mutationFn: (id: number) => bookContentLanguageService.deleteBookContentLanguage(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBookContentLanguage,
    useUpdateBookContentLanguage,
    useDeleteBookContentLanguage
}