import {useMutation} from "@tanstack/react-query";
import {PublisherHouseBody} from "../../models";
import {createPublisherHouse, deletePublisherHouse, updatePublisherHouse} from "../../service";
import toast from "react-hot-toast";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const usePublisherHouseCreate = (refetch: any) => {

    return useMutation({
        mutationKey: ['create publisher house'],
        mutationFn: (body: PublisherHouseBody) => createPublisherHouse(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const usePublisherHouseUpdate = (refetch: any) => {

    return useMutation({
        mutationKey: ['update publisher house'],
        mutationFn: (body: PublisherHouseBody) => updatePublisherHouse(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const usePublisherHouseDelete = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete publisher house'],
        mutationFn: (id: number) => deletePublisherHouse(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    usePublisherHouseCreate,
    usePublisherHouseUpdate,
    usePublisherHouseDelete
}