import {jwtDecode} from "jwt-decode";
import {JwtPayload} from "../models";

export function decodeJwt(): JwtPayload | string {
    const token = localStorage.getItem("accessToken") || '';
    if (token === '') {
        return ''
    }
    return jwtDecode<JwtPayload>(token);
}
