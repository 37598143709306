import {client} from "../api";
import {BookPaperQualityBody, BookPaperQuality, BookPaperQualities, ResponseError, ResponseSuccess} from "../models";


class BookPaperQualityService {

    async getBookPaperQualities(page: number, pageSize: number): Promise<ResponseSuccess<BookPaperQualities> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookPaperQualities> | ResponseError>(`/api/v1/admin/book-paper-qualities/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBookPaperQualityByID(id: number): Promise<ResponseSuccess<BookPaperQuality> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookPaperQuality> | ResponseError>(`/api/v1/admin/book-paper-qualities/${id}`)).data;
    }

    async createBookPaperQuality(body: BookPaperQualityBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-paper-qualities`, body)).data;
    }

    async updateBookPaperQuality(body: BookPaperQualityBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-paper-qualities`, body)).data;
    }

    async deleteBookPaperQuality(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-paper-qualities/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BookPaperQualityService();