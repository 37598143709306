import {client} from "../api";
import {BookCoverTypeBody, BookCoverType, BookCoverTypes, ResponseError, ResponseSuccess} from "../models";


class BookCoverTypeService {

    async getBookCoverTypes(page: number, pageSize: number): Promise<ResponseSuccess<BookCoverTypes> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookCoverTypes> | ResponseError>(`/api/v1/admin/book-cover-types/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBookCoverTypeByID(id: number): Promise<ResponseSuccess<BookCoverType> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookCoverType> | ResponseError>(`/api/v1/admin/book-cover-types/${id}`)).data;
    }

    async createBookCoverType(body: BookCoverTypeBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-cover-types`, body)).data;
    }

    async updateBookCoverType(body: BookCoverTypeBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-cover-types`, body)).data;
    }

    async deleteBookCoverType(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-cover-types/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BookCoverTypeService();