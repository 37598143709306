import React from 'react';
import {useParams} from 'react-router-dom';
import EditAddBookCoverType from './EditAddBookCoverType';
import {useGetBookCoverType} from '../../hooks';

const BookCoverTypeEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetBookCoverType(Number(id));


    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess
                        ?
                        <EditAddBookCoverType bookCoverType={data.success.data} isEdit={true}/>
                        :
                        <></>
            }
        </div>
    );

};

export default BookCoverTypeEdit;