import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BookSegmentBody} from "../../models";
import {bookSegmentService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBookSegment = (refetch: any) => {

    return useMutation({
        mutationKey: ['create book segment'],
        mutationFn: (body: BookSegmentBody) => bookSegmentService.createBookSegment(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateBookSegment = (refetch: any) => {

    return useMutation({
        mutationKey: ['update book segment'],
        mutationFn: (body: BookSegmentBody) => bookSegmentService.updateBookSegment(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBookSegment = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete book segment'],
        mutationFn: (id: number) => bookSegmentService.deleteBookSegment(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBookSegment,
    useUpdateBookSegment,
    useDeleteBookSegment
}