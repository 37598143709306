import axios, {AxiosResponse} from "axios";
import {getAccessToken} from "../utils/getAccessToken";
import {getRefreshTokenSRV} from "../service";
import {RefreshToken, ResponseSuccess} from "../models";

const client = axios.create({
    baseURL: 'https://berlin-dev.knijka.com/',
    headers: {
        "Accept-Language": "tr-TR"
    },
    timeout: 2000
});

client.interceptors.request.use(
    (config) => {
        // Get the token
        const token = getAccessToken();

        // If token exists, add it to the request headers
        if (token && !config.url?.includes('refresh-token')) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


client.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        const res = getRefreshTokenSRV();
        res.then((response: AxiosResponse<ResponseSuccess<RefreshToken>>) => {
            localStorage.setItem('accessToken', response.data.success.data.accessToken);
            localStorage.setItem('refreshToken', response.data.success.data.refreshToken);
        }).catch(() => {
            localStorage.clear();
            window.location.replace("/login/");
        })
    }
    return Promise.reject(error);
});
export default client;

