import {client} from "../api";
import {BrandBody, Brand, Brands, ResponseError, ResponseSuccess} from "../models";


class BrandService {

    async getBrands(page: number, pageSize: number): Promise<ResponseSuccess<Brands> | ResponseError | any> {
        return (await client.get<ResponseSuccess<Brands> | ResponseError>(`/api/v1/admin/brands/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBrandByID(id: number): Promise<ResponseSuccess<Brand> | ResponseError | any> {
        return (await client.get<ResponseSuccess<Brand> | ResponseError>(`/api/v1/admin/brands/${id}`)).data;
    }

    async createBrand(body: BrandBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/brands`, body)).data;
    }

    async updateBrand(body: BrandBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/brands`, body)).data;
    }

    async deleteBrand(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/brands/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BrandService();