import {useQuery} from "@tanstack/react-query";
import {ProductTypes, ProductType, ResponseSuccess, AvailableProperties, PropertyOfProductType} from "../../models";
import {productTypeService} from "../../service";


const useProductTypes = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<ProductTypes>>({
        queryFn: () => productTypeService.getProductTypes(page, pageSize),
        queryKey: ['product-types']
    })
}


const useGetProductType = (id: number) => {
    return useQuery<ResponseSuccess<ProductType>>({
        queryFn: () => productTypeService.getProductTypeByID(id),
        queryKey: ['product-type', id],
    })
}


const useGetAvailableProperties = (id: number) => {
    return useQuery<ResponseSuccess<AvailableProperties[]>>({
        queryFn: () => productTypeService.getProductTypeAvailableProperty(id),
        queryKey: ['product-type-available', id]
    })
}


const useGetProductTypeProperty = (id: number) => {
    return useQuery<ResponseSuccess<PropertyOfProductType[]>>({
        queryFn: () => productTypeService.getProductTypeProperty(id),
        queryKey: ['product-type-property', id]
    })
}

export {
    useProductTypes,
    useGetProductType,
    useGetProductTypeProperty,
    useGetAvailableProperties
};