import {client} from "../api";
import {Auth, RefreshToken, ResponseError, ResponseSuccess} from "../models";
import {getRefreshTokenStorage} from "../utils/getAccessToken";


export async function login(email: string, password: string): Promise<ResponseSuccess<Auth> | ResponseError | any> {
    const body = {
        email: email,
        password: password
    }
    return await client.post<ResponseSuccess<Auth> | ResponseError>('api/v1/auth/admin/sign-in', body);
}


export async function getRefreshToken(): Promise<ResponseSuccess<RefreshToken> | ResponseError | any> {
    const token = getRefreshTokenStorage();
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return await client.get<ResponseSuccess<Auth> | ResponseError>('/api/v1/auth/refresh-token', {headers: headers});
}