import '../../styles/pages/property.scss';
import React, {FC, useEffect, useState} from 'react';
import {SubmitButton, TextInput} from '../../components';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {useCreateProperty, useGetProperties, useUpdateProperty} from '../../hooks';
import PropertyInput from './PropertyInput';
import {CreatePropertyBody, Property} from '../../models';


export interface PropertyInputs {
    name: string
    description: string
}

interface Props {
    property?: Property;
    isEdit: boolean;
}

const EditAddProperty: FC<Props> = ({isEdit, property}) => {
    const {refetch} = useGetProperties(0, 20);
    const {mutate: create} = useCreateProperty(refetch);
    const {mutate: update} = useUpdateProperty(refetch);

    const [propertyShortCode, setPropertyShortCode] = useState<string>('');

    const [propertyLangListTR, setPropertyLangListTR] = useState<PropertyInputs>({
        name: '',
        description: ''
    });

    const [propertyLangListRU, setPropertyLangListRU] = useState<PropertyInputs>({
        name: '',
        description: ''
    });

    const [propertyLangListEN, setPropertyLangListEN] = useState<PropertyInputs>({
        name: '',
        description: ''
    });
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (isEdit) {
            if (property) {
                if ('productPropertyId' in property) {
                    setPropertyShortCode(property.shortCode);
                    for (let i = 0; i < property.productPropertyLangList.length; i++) {
                        if (property.productPropertyLangList[i].langCode === 'TR') {
                            setPropertyLangListTR((prevData) => ({
                                ...prevData,
                                name: property.productPropertyLangList[i].name,
                                description: property.productPropertyLangList[i].description
                            }));
                        } else if (property.productPropertyLangList[i].langCode === 'RU') {
                            setPropertyLangListRU((prevData) => ({
                                ...prevData,
                                name: property.productPropertyLangList[i].name,
                                description: property.productPropertyLangList[i].description
                            }));
                        } else if (property.productPropertyLangList[i].langCode === 'EN') {
                            setPropertyLangListEN((prevData) => ({
                                ...prevData,
                                name: property.productPropertyLangList[i].name,
                                description: property.productPropertyLangList[i].description
                            }));
                        }
                    }
                }
            }
        }
    }, [isEdit, property]);


    const handlePropertyChangeTR = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPropertyLangListTR((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePropertyChangeRU = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPropertyLangListRU((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePropertyChangeEN = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPropertyLangListEN((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: CreatePropertyBody = {
            shortCode: propertyShortCode,
            productPropertyLangList: [
                {
                    name: propertyLangListTR.name,
                    description: propertyLangListTR.description,
                    langCode: 'TR'
                },
                {
                    name: propertyLangListRU.name,
                    description: propertyLangListRU.description,
                    langCode: 'RU'
                },
                {
                    name: propertyLangListEN.name,
                    description: propertyLangListEN.description,
                    langCode: 'EN'
                }
            ]

        } as CreatePropertyBody;

        if (isEdit) {
            let langList = []
            if (property) {
                for (let i = 0; i < property.productPropertyLangList.length; i++) {
                    if (property.productPropertyLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                productPropertyLangId: property.productPropertyLangList[i].productPropertyLangId,
                                name: propertyLangListTR.name,
                                description: propertyLangListTR.description,
                                langCode: 'TR'
                            }
                        );
                    } else if (property.productPropertyLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                productPropertyLangId: property.productPropertyLangList[i].productPropertyLangId,
                                name: propertyLangListRU.name,
                                description: propertyLangListRU.description,
                                langCode: 'RU'
                            }
                        );
                    } else if (property.productPropertyLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                productPropertyLangId: property.productPropertyLangList[i].productPropertyLangId,
                                name: propertyLangListEN.name,
                                description: propertyLangListEN.description,
                                langCode: 'EN'
                            }
                        );
                    }
                }
            }
            body.productPropertyLangList = langList;
            body.productPropertyId = property?.productPropertyId;
            update(body);
        } else {
            create(body);
            setPropertyLangListTR({
                name: '',
                description: ''
            });
            setPropertyLangListRU({
                name: '',
                description: ''
            });
            setPropertyLangListEN({
                name: '',
                description: ''
            });
            setPropertyShortCode('');
        }

    };

    return (
        <div className='property-add-edit'>
            <div className='property-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='property-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className='property-add-edit__form_item'>
                    <TextInput
                        placeholder='Short Code'
                        name='shortCode'
                        onChange={((e: React.ChangeEvent<HTMLInputElement>) => {
                            setPropertyShortCode(e.target.value);
                        })}
                        value={propertyShortCode}
                    />
                </div>
                <PropertyInput
                    lang='TR'
                    langList={propertyLangListTR}
                    onChange={handlePropertyChangeTR}
                />
                <PropertyInput
                    lang='RU'
                    langList={propertyLangListRU}
                    onChange={handlePropertyChangeRU}
                />
                <PropertyInput
                    lang='EN'
                    langList={propertyLangListEN}
                    onChange={handlePropertyChangeEN}
                />
                <div className="property-add-edit__form__item flex-property-form-btn">
                    <div className='property-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditAddProperty;