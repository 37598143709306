import {client} from "../api";
import {
    BookPublishingBrandBody,
    BookPublishingBrand,
    BookPublishingBrands,
    ResponseError,
    ResponseSuccess
} from "../models";


class BookPublishingBrandService {

    async getBookPublishingBrands(page: number, pageSize: number): Promise<ResponseSuccess<BookPublishingBrands> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookPublishingBrands> | ResponseError>(`/api/v1/admin/book-publishing-brands/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getBookPublishingBrandByID(id: number): Promise<ResponseSuccess<BookPublishingBrand> | ResponseError | any> {
        return (await client.get<ResponseSuccess<BookPublishingBrand> | ResponseError>(`/api/v1/admin/book-publishing-brands/${id}`)).data;
    }

    async createBookPublishingBrand(body: BookPublishingBrandBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-publishing-brands`, body)).data;
    }

    async updateBookPublishingBrand(body: BookPublishingBrandBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-publishing-brands`, body)).data;
    }

    async deleteBookPublishingBrand(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/book-publishing-brands/${id}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new BookPublishingBrandService();