import {client} from "../api";
import {
    CreatePropertyBody,
    Property,
    Properties,
    ResponseError,
    ResponseSuccess,
    PropertyValue,
    CreatePropertyValue
} from "../models";


class PropertyService {

    async getProperties(page: number, pageSize: number): Promise<ResponseSuccess<Properties> | ResponseError | any> {
        return (await client.get<ResponseSuccess<Properties> | ResponseError>(`/api/v1/admin/product-properties/browse?page=${page}&size=${pageSize}`)).data;
    }

    async getPropertyByID(id: number): Promise<ResponseSuccess<Property> | ResponseError | any> {
        return (await client.get<ResponseSuccess<Property> | ResponseError>(`/api/v1/admin/product-properties/${id}`)).data;
    }

    async createProperty(body: CreatePropertyBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-properties`, body)).data;
    }

    async updateProperty(body: CreatePropertyBody): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-properties`, body)).data;
    }

    async deleteProperty(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-properties/${id}`)).data;
    }

    async getPropertyValueByID(id: number): Promise<ResponseSuccess<PropertyValue> | ResponseError | any> {
        return (await client.get<ResponseSuccess<PropertyValue> | ResponseError>(`/api/v1/admin/product-property-values/${id}`)).data;
    }

    async createPropertyValue(body: CreatePropertyValue): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-property-values`, body)).data;
    }

    async updatePropertyValue(body: CreatePropertyValue): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-property-values`, body)).data;
    }

    async deletePropertyValue(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
        return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/product-property-values/${id}`)).data;
    }

}


// eslint-disable-next-line import/no-anonymous-default-export
export default new PropertyService();