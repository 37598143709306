import {PageContent} from '../../components';
import {useGetProducts, useProductDelete} from '../../hooks';


const columns: string[] = [
    'ID', 'Image', 'Title', 'Publish', 'Book'
]

const Product = () => {
    const {data, isSuccess, refetch} = useGetProducts(0, 20);
    const {mutate: deleteProduct} = useProductDelete(refetch);


    return <PageContent
        title='Product'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/product/add/'
        deleteItem={deleteProduct}
    />;
};

export default Product;