import {PageContent} from '../../components';
import {useGetBookPaperQualities, useDeleteBookPaperQuality} from '../../hooks';


const columns: string[] = [
    'ID', 'Name'
]

const BookPaperQuality = () => {
    const {data, isSuccess, refetch} = useGetBookPaperQualities(0, 20);
    const {mutate: deleteMenu} = useDeleteBookPaperQuality(refetch);


    return <PageContent
        title='Book Paper Quality'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        totalPage={isSuccess ? data.success.data.totalPages : 0}
        addLink='/book-paper-quality/add/'
        deleteItem={deleteMenu}
    />;
};

export default BookPaperQuality;