import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {AuthorBody} from "../../models";
import {createAuthor, deleteAuthor, updateAuthor} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useAuthorCreate = (refetch: any) => {

    return useMutation({
        mutationKey: ['create author'],
        mutationFn: (body: AuthorBody) => createAuthor(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useAuthorUpdate = (refetch: any) => {

    return useMutation({
        mutationKey: ['update author'],
        mutationFn: (body: AuthorBody) => updateAuthor(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useAuthorDelete = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete author'],
        mutationFn: (id: number) => deleteAuthor(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useAuthorCreate,
    useAuthorUpdate,
    useAuthorDelete
}