import {PageContent} from '../../components';
import {useGetProperties, useDeleteProperty} from '../../hooks';


const columns: string[] = [
    'ID', 'Name', 'Description', 'Short Code'
]

const Property = () => {
    const {data, isSuccess, refetch} = useGetProperties(0, 20);
    const {mutate: deleteMenu} = useDeleteProperty(refetch);


    return <PageContent
        title='Property'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/property/add/'
        deleteItem={deleteMenu}
    />;
};

export default Property;