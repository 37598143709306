import {useParams} from 'react-router-dom';
import EditProductType from './EditProductType';
import {useGetAvailableProperties, useGetProductType} from '../../hooks';

const ProductTypeEdit = () => {
    let {id} = useParams();
    const {data, isLoading, isSuccess} = useGetProductType(Number(id));
    const {data: availableProperties, isSuccess: isSuccessProperties} = useGetAvailableProperties(Number(id));

    return (
        <div>
            {
                isLoading
                    ?
                    <></>
                    :
                    isSuccess && isSuccessProperties
                        ?
                        <EditProductType
                            productType={data.success.data}
                            isEdit={true}
                            productTypeId={data.success.data.productTypeId}
                            availableProperties={availableProperties.success.data}
                        />
                        :
                        <></>
            }
        </div>
    );
};

export default ProductTypeEdit;