import {PageContent} from '../../components';
import {usePublisherHouseDelete} from '../../hooks/PublisherHouse/usePublisherHouseMutation';
import {useGetPublisherHouses} from '../../hooks/PublisherHouse/usePublisherHouseQueries';


const columns: string[] = [
    'ID', 'Name'
]

const PublisherHouse = () => {
    const {data, isSuccess, refetch} = useGetPublisherHouses(0, 20);
    const {mutate: deletePublisherHouse} = usePublisherHouseDelete(refetch);

    return <PageContent
        title='Publisher House'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/publisher-house/add/'
        deleteItem={deletePublisherHouse}
    />;
};

export default PublisherHouse;