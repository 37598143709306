import React, {FC} from 'react';
import {Dropdown} from '../../components';
import {BrandContent} from '../../models';

interface Props {
    brands: BrandContent[]
    selectedValue: string;
    onSelect: (value: string) => void;
    selectedOption: string;
    setSelectedOption: (value: string) => void;
}


const PureProduct: FC<Props> = ({brands, selectedValue, onSelect, selectedOption, setSelectedOption}) => {
    return <Dropdown
        onSelect={onSelect}
        options={brands}
        selectedOption={selectedOption}
        selectedValue={selectedValue}
        setSelectedOption={setSelectedOption}
        title='Select Brand'
    />;
};

export default PureProduct;