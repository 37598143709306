import {PageContent} from '../../components';
import {useMenuDelete, useMenuSetVisible} from '../../hooks/Menu/useMenuMutation';
import {useGetMenus} from '../../hooks/Menu/useMenuQueries';


const columns: string[] = [
    'ID', 'Name', 'Short Code', 'Visible'
]

const Menu = () => {
    const {data, isSuccess, refetch} = useGetMenus(0, 20);
    const {mutate: deleteMenu} = useMenuDelete(refetch);
    const {mutate: setVisibleMenu} = useMenuSetVisible(refetch);

    const setVisible = (id: number, isVisible: boolean) => {
        setVisibleMenu({
            menuId: id,
            isVisible: isVisible
        })
    }

    return <PageContent
        title='Menu'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        addLink='/menu/add/'
        deleteItem={deleteMenu}
        setVisible={setVisible}
    />;
};

export default Menu;