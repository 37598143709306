import React from 'react';
import '../../../styles/components/UI/TextArea.scss';


interface TextAreaProps {
    name: string;
    value: string;
    onChange: (e: any) => void;
    placeholder?: string;
    rows?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
                                               name,
                                               value,
                                               onChange,
                                               placeholder = '',
                                               rows = 3,
                                           }) => {

    return (
        <textarea
            className="custom-textarea"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
        />
    );
};

export default TextArea;
