import {useQuery} from "@tanstack/react-query";
import {Product, Products, ResponseSuccess} from "../../models";
import {productService} from "../../service";


const useGetProducts = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<Products>>({
        queryFn: () => productService.getProducts(page, pageSize),
        queryKey: ['products']
    })
}


const useGetProduct = (id: number) => {
    return useQuery<ResponseSuccess<Product>>({
        queryFn: () => productService.getProductByID(id),
        queryKey: ['product', id]
    })
}

export {
    useGetProducts,
    useGetProduct
};