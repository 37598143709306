import {PageContent} from '../../components';
import {useGetBookPublishingBrands, useDeleteBookPublishingBrand} from '../../hooks';


const columns: string[] = [
    'ID', 'Name'
]

const BookPublishingBrand = () => {
    const {data, isSuccess, refetch} = useGetBookPublishingBrands(0, 20);
    const {mutate: deleteMenu} = useDeleteBookPublishingBrand(refetch);


    return <PageContent
        title='Book Publishing Brand'
        columns={columns}
        items={isSuccess ? data.success.data.content : []}
        totalPage={isSuccess ? data.success.data.totalPages : 0}
        addLink='/book-publishing-brand/add/'
        deleteItem={deleteMenu}
    />;
};

export default BookPublishingBrand;