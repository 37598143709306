import {useQuery} from "@tanstack/react-query";
import {Menu, MenuByID, ResponseSuccess} from "../../models";
import {getFilterMenusSRV, getMenu} from "../../service";

const useGetMenus = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<Menu>>({
        queryFn: () => getFilterMenusSRV("", page, pageSize),
        queryKey: ['menus']
    })
}


const useGetMenu = (id: number) => {
    return useQuery<ResponseSuccess<MenuByID>>({
        queryFn: () => getMenu(id),
        queryKey: ['menu', id]
    })
}

export {
    useGetMenus,
    useGetMenu
};