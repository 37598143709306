import '../../styles/pages/publisherHouses.scss';
import React, {FC, useEffect, useState} from 'react';
import {SubmitButton, TextInput} from '../../components';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {useGetPublisherHouses} from '../../hooks/PublisherHouse/usePublisherHouseQueries';
import {PublisherHouseBody, PublisherHouseById} from '../../models/publisherHouse.model';
import {usePublisherHouseCreate, usePublisherHouseUpdate} from '../../hooks/PublisherHouse/usePublisherHouseMutation';


interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
}

interface Props {
    publisherHouses?: PublisherHouseById;
    isEdit: boolean;
}

const EditAddPublisherHouse: FC<Props> = ({isEdit, publisherHouses}) => {
    const {refetch} = useGetPublisherHouses(0, 20);
    const {mutate: create} = usePublisherHouseCreate(refetch);
    const {mutate: update} = usePublisherHouseUpdate(refetch);

    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: ''
    });
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (isEdit) {
            if (publisherHouses) {
                if ('publisherHouseId' in publisherHouses) {
                    for (let i = 0; i < publisherHouses.publisherHouseLangList.length; i++) {
                        if (publisherHouses.publisherHouseLangList[i].langCode === 'TR') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameTR: publisherHouses.publisherHouseLangList[i].name,
                            }));
                        } else if (publisherHouses.publisherHouseLangList[i].langCode === 'RU') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameRU: publisherHouses.publisherHouseLangList[i].name,
                            }));
                        } else if (publisherHouses.publisherHouseLangList[i].langCode === 'EN') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameEN: publisherHouses.publisherHouseLangList[i].name,
                            }));
                        }
                    }
                }
            }
        }
    }, [isEdit, publisherHouses]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: PublisherHouseBody = {
            publisherHouseLangList: [
                {
                    name: formData.nameTR,
                    langCode: 'TR'
                },
                {
                    name: formData.nameRU,
                    langCode: 'RU'
                },
                {
                    name: formData.nameEN,
                    langCode: 'EN'
                }
            ]

        } as PublisherHouseBody;

        if (isEdit) {
            let langList = []
            if (publisherHouses) {
                for (let i = 0; i < publisherHouses.publisherHouseLangList.length; i++) {
                    if (publisherHouses.publisherHouseLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                publisherHouseLangId: publisherHouses.publisherHouseLangList[i].publisherHouseLangId,
                                name: formData.nameTR,
                                langCode: 'TR'
                            }
                        );
                    } else if (publisherHouses.publisherHouseLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                publisherHouseLangId: publisherHouses.publisherHouseLangList[i].publisherHouseLangId,
                                name: formData.nameRU,
                                langCode: 'RU'
                            }
                        );
                    } else if (publisherHouses.publisherHouseLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                publisherHouseLangId: publisherHouses.publisherHouseLangList[i].publisherHouseLangId,
                                name: formData.nameEN,
                                langCode: 'EN'
                            }
                        );
                    }
                }
            }
            body.publisherHouseLangList = langList
            body.publisherHouseId = publisherHouses?.publisherHouseId;
            update(body);

        } else {
            create(body);
            setFormData({
                nameTR: '',
                nameRU: '',
                nameEN: ''
            });
        }

    };

    return (
        <div className='publisherHouse-add-edit'>
            <div className='publisherHouse-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='publisherHouse-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className="publisherHouse-add-edit__form__item">
                    <TextInput
                        placeholder='TR Name'
                        name='nameTR'
                        onChange={handleChange}
                        value={formData.nameTR}
                    />
                </div>
                <div className="publisherHouse-add-edit__form__item">
                    <TextInput
                        placeholder='RU Name'
                        name='nameRU'
                        onChange={handleChange}
                        value={formData.nameRU}
                    />
                </div>
                <div className="publisherHouse-add-edit__form__item">
                    <TextInput
                        placeholder='EN Name'
                        name='nameEN'
                        onChange={handleChange}
                        value={formData.nameEN}
                    />
                </div>
                <div className="publisherHouse-add-edit__form__item flex-publisherHouse-form-btn">
                    <div className='publisherHouse-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditAddPublisherHouse;