import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BookPublishingBrandBody} from "../../models";
import {bookPublishingBrandService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBookPublishingBrand = (refetch: any) => {

    return useMutation({
        mutationKey: ['create book publishing brand'],
        mutationFn: (body: BookPublishingBrandBody) => bookPublishingBrandService.createBookPublishingBrand(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateBookPublishingBrand = (refetch: any) => {

    return useMutation({
        mutationKey: ['update book publishing brand'],
        mutationFn: (body: BookPublishingBrandBody) => bookPublishingBrandService.updateBookPublishingBrand(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBookPublishingBrand = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete book publishing brand'],
        mutationFn: (id: number) => bookPublishingBrandService.deleteBookPublishingBrand(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBookPublishingBrand,
    useUpdateBookPublishingBrand,
    useDeleteBookPublishingBrand
}