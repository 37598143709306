import '../../styles/pages/menu.scss';
import React, {FC, useEffect, useState} from 'react';
import {CheckBox, SubmitButton, TextInput} from '../../components';
import {MenuBody, MenuByID} from '../../models';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackCircle} from "react-icons/io5";
import {useGetMenus} from '../../hooks/Menu/useMenuQueries';
import {useMenuCreate, useMenuUpdate} from '../../hooks/Menu/useMenuMutation';


interface FormData {
    nameTR: string;
    nameRU: string;
    nameEN: string;
    slug: string;
    isVisible: boolean;
}

interface Props {
    menu?: MenuByID;
    isEdit: boolean;
}

const EditAddMenu: FC<Props> = ({isEdit, menu}) => {
    const {refetch} = useGetMenus(0, 20);
    const {mutate: create} = useMenuCreate(refetch);
    const {mutate: update} = useMenuUpdate(refetch);

    const [formData, setFormData] = useState<FormData>({
        nameTR: '',
        nameRU: '',
        nameEN: '',
        slug: '',
        isVisible: false
    });
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (isEdit) {
            if (menu) {
                if ('menuId' in menu) {
                    for (let i = 0; i < menu.menuLangList.length; i++) {
                        if (menu.menuLangList[i].langCode === 'TR') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameTR: menu.menuLangList[i].name,
                            }));
                        } else if (menu.menuLangList[i].langCode === 'RU') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameRU: menu.menuLangList[i].name,
                            }));
                        } else if (menu.menuLangList[i].langCode === 'EN') {
                            setFormData((prevData) => ({
                                ...prevData,
                                nameEN: menu.menuLangList[i].name,
                            }));
                        }
                    }
                    setFormData((prevData) => ({
                        ...prevData,
                        slug: menu.shortCode,
                        isVisible: menu.isVisible
                    }));
                }
            }
        }
    }, [menu]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (name === 'isVisible') {
            setFormData((prevData) => ({
                ...prevData,
                isVisible: !formData.isVisible,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: MenuBody = {
            shortCode: formData.slug,
            isVisible: formData.isVisible,
            menuLangList: [
                {
                    name: formData.nameTR,
                    langCode: 'TR'
                },
                {
                    name: formData.nameRU,
                    langCode: 'RU'
                },
                {
                    name: formData.nameEN,
                    langCode: 'EN'
                }
            ]

        } as MenuBody;

        if (isEdit) {
            let langList = []
            if (menu) {
                for (let i = 0; i < menu.menuLangList.length; i++) {
                    if (menu.menuLangList[i].langCode === 'TR') {
                        langList.push(
                            {
                                menuLangId: menu.menuLangList[i].menuLangId,
                                name: formData.nameTR,
                                langCode: 'TR'
                            }
                        );
                    } else if (menu.menuLangList[i].langCode === 'RU') {
                        langList.push(
                            {
                                menuLangId: menu.menuLangList[i].menuLangId,
                                name: formData.nameRU,
                                langCode: 'RU'
                            }
                        );
                    } else if (menu.menuLangList[i].langCode === 'EN') {
                        langList.push(
                            {
                                menuLangId: menu.menuLangList[i].menuLangId,
                                name: formData.nameEN,
                                langCode: 'EN'
                            }
                        );
                    }
                }
            }
            body.menuLangList = langList
            body.menuId = menu?.menuId;
            update(body);

        } else {
            create(body);
            setFormData({
                nameTR: '',
                nameRU: '',
                nameEN: '',
                slug: '',
                isVisible: false
            });
        }

    };

    return (
        <div className='menu-add-edit'>
            <div className='menu-add-edit__back-btn' onClick={goBack}><IoArrowBackCircle/></div>
            <form className='menu-add-edit__form' action="" onSubmit={handleSubmit}>
                <div className="menu-add-edit__form__item">
                    <TextInput
                        placeholder='TR Name'
                        name='nameTR'
                        onChange={handleChange}
                        value={formData.nameTR}
                    />
                </div>
                <div className="menu-add-edit__form__item">
                    <TextInput
                        placeholder='RU Name'
                        name='nameRU'
                        onChange={handleChange}
                        value={formData.nameRU}
                    />
                </div>
                <div className="menu-add-edit__form__item">
                    <TextInput
                        placeholder='EN Name'
                        name='nameEN'
                        onChange={handleChange}
                        value={formData.nameEN}
                    />
                </div>
                <div className="menu-add-edit__form__item">
                    <TextInput
                        placeholder='Short Code'
                        name='slug'
                        onChange={handleChange}
                        value={formData.slug}
                    />
                </div>
                <div>
                    <CheckBox
                        label='Visible'
                        name='isVisible'
                        value='isVisible'
                        checked={formData.isVisible}
                        onChange={handleChange}
                    />
                </div>
                <div className="menu-add-edit__form__item flex-menu-form-btn">
                    <div className='menu-add-edit__form__btn'>
                        <SubmitButton
                            title={isEdit ? 'Update' : 'Save'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditAddMenu;