// Dropdown.tsx

import React from 'react';
import '../../../styles/components/UI/Dropdown.scss';
import {
    BookContentLanguageContent,
    BookCoverTypeContent,
    BookPaperQualityContent, BookPublishingBrandContent,
    BookSegmentContent,
    BookTypeContent,
    BrandContent,
    CategoryContent,
    MenuContent,
    ProductTypeContent,
    PublisherHouseContent,
    SeriesContent
} from '../../../models';

interface Option {
    value: string;
    label: string;
}

interface DropdownProps {
    title: string;
    options: (CategoryContent | MenuContent | PublisherHouseContent | SeriesContent | BrandContent | ProductTypeContent | BookTypeContent | BookSegmentContent | BookPaperQualityContent | BookCoverTypeContent | BookContentLanguageContent | BookPublishingBrandContent)[];
    selectedValue: string;
    onSelect: (value: string) => void;
    selectedOption: string;
    setSelectedOption: (value: string) => void;
}


const Dropdown: React.FC<DropdownProps> = ({title, options, onSelect, selectedOption, setSelectedOption}) => {

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        onSelect(selectedValue);
    };

    const getDropDownContent = (item: (CategoryContent | MenuContent | PublisherHouseContent | SeriesContent | BrandContent | ProductTypeContent | BookTypeContent | BookSegmentContent | BookPaperQualityContent | BookCoverTypeContent | BookContentLanguageContent | BookPublishingBrandContent)) => {
        if ('categoryId' in item) {
            return <option key={item.categoryId} value={item.categoryId}>{item.categoryLang.name}</option>
        } else if ('menuId' in item) {
            return <option key={item.menuId} value={item.menuId}>{item.menuLang.name}</option>
        } else if ('publisherHouseId' in item) {
            return <option key={item.publisherHouseId}
                           value={item.publisherHouseId}>{item.publisherHouseLang.name}</option>
        } else if ('seriesId' in item) {
            return <option key={item.seriesId} value={item.seriesId}>{item.seriesLang.name}</option>
        } else if ('brandId' in item) {
            return <option key={item.brandId} value={item.brandId}>{item.brandLang.name}</option>
        } else if ('productTypeId' in item) {
            return <option key={item.productTypeId} value={item.productTypeId}>{item.productTypeLang.name}</option>
        } else if ('bookTypeId' in item) {
            return <option key={item.bookTypeId} value={item.bookTypeId}>{item.name}</option>
        } else if ('bookSegmentId' in item) {
            return <option key={item.bookSegmentId} value={item.bookSegmentId}>{item.name}</option>
        } else if ('bookPaperQualityId' in item) {
            return <option key={item.bookPaperQualityId} value={item.bookPaperQualityId}>{item.name}</option>
        } else if ('bookCoverTypeId' in item) {
            return <option key={item.bookCoverTypeId} value={item.bookCoverTypeId}>{item.name}</option>
        } else if ('bookContentLanguageId' in item) {
            return <option key={item.bookContentLanguageId} value={item.bookContentLanguageId}>{item.name}</option>
        } else if ('bookPublishingBrandId' in item) {
            return <option key={item.bookPublishingBrandId} value={item.bookPublishingBrandId}>{item.name}</option>
        }

    }

    return (
        <div className="dropdown">
            <select value={selectedOption} onChange={handleSelect}>
                <option value="">{title}</option>
                {options.map((item) => getDropDownContent(item))}
            </select>
        </div>
    );
};

export default Dropdown;
