import {client} from "../api";
import {Categories, Category, CategoryBody, CategoryContent, ResponseError, ResponseSuccess} from "../models";


export async function getCategories(page: number, pageSize: number): Promise<ResponseSuccess<Categories> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Categories> | ResponseError>(`/api/v1/admin/categories/list?page=${page}&size=${pageSize}`)).data;
}

export async function getFilterCategories(name: string, page: number, pageSize: number): Promise<ResponseSuccess<Categories> | ResponseError | any> {
    return await client.get<ResponseSuccess<Categories> | ResponseError>(`/api/v1/admin/categories/browse?name=${name}&page=${page}&size=${pageSize}`);
}

export async function getCategoriesExclude(id: number): Promise<ResponseSuccess<CategoryContent[]> | ResponseError | any> {
    return (await client.get<ResponseSuccess<CategoryContent[]> | ResponseError>(`/api/v1/admin/categories?exclude=${id}`)).data;
}


export async function getCategoryByID(id: number): Promise<ResponseSuccess<Category> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Category> | ResponseError>(`/api/v1/admin/categories/${id}`)).data;
}

export async function createCategory(body: CategoryBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.post<ResponseSuccess<Category> | ResponseError>(`/api/v1/admin/categories`, body)).data;
}

export async function updateCategory(body: CategoryBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.put<ResponseSuccess<Category> | ResponseError>(`/api/v1/admin/categories`, body)).data;
}

export async function deleteCategory(id: number): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.delete<ResponseSuccess<Category> | ResponseError>(`/api/v1/admin/categories/${id}`)).data;
}