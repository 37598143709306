import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {BookPaperQualities, BookPaperQuality, ResponseSuccess} from "../../models";
import {bookPaperQualityService} from "../../service";


const useGetBookPaperQualities = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<BookPaperQualities>>({
        queryFn: () => bookPaperQualityService.getBookPaperQualities(page, pageSize),
        queryKey: ['book-paper-qualities'],
        placeholderData: keepPreviousData
    })
}


const useGetBookPaperQuality = (id: number) => {
    return useQuery<ResponseSuccess<BookPaperQuality>>({
        queryFn: () => bookPaperQualityService.getBookPaperQualityByID(id),
        queryKey: ['book-paper-quality', id]
    })
}


export {
    useGetBookPaperQualities,
    useGetBookPaperQuality
};