import React, {useState} from 'react';
import {Content, Header, Sidebar} from '../components';

import "../styles/Layout/Layout.scss";

const MainLayout = () => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const toggleSidebar = (e: any) => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="layout">
            <header>
                <Header/>
            </header>
            <main>
                <Sidebar
                    isOpen={isOpen}
                    toggleSidebar={toggleSidebar}
                />
                <Content/>
            </main>
        </div>
    );
};

export default MainLayout;