import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {CategoryBody} from "../../models";
import {createCategorySRV, deleteCategorySRV, updateCategorySRV} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCategoryCreate = (refetch: any) => {

    return useMutation({
        mutationKey: ['create category'],
        mutationFn: (body: CategoryBody) => createCategorySRV(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useCategoryUpdate = (refetch: any) => {

    return useMutation({
        mutationKey: ['update category'],
        mutationFn: (body: CategoryBody) => updateCategorySRV(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useCategoryDelete = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete category'],
        mutationFn: (id: number) => deleteCategorySRV(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCategoryCreate,
    useCategoryUpdate,
    useCategoryDelete
}