import {client} from "../api";
import {ResponseError, ResponseSuccess, Series, SeriesBody, SeriesById} from "../models";


export async function getSeriesById(id: number): Promise<ResponseSuccess<SeriesById> | ResponseError | any> {
    return (await client.get<ResponseSuccess<SeriesById> | ResponseError>(`/api/v1/admin/series/${id}`)).data;
}

export async function getSeries(page: number, pageSize: number): Promise<ResponseSuccess<Series> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Series> | ResponseError>(`/api/v1/admin/series?page=${page}&size=${pageSize}`)).data;
}

export async function getFilterSeries(name: string, page: number, pageSize: number): Promise<ResponseSuccess<Series> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Series> | ResponseError>(`/api/v1/admin/series/browse?name=${name}&page=${page}&size=${pageSize}`)).data;
}

export async function createSeries(body: SeriesBody): Promise<ResponseSuccess<Series> | ResponseError | any> {
    return (await client.post<ResponseSuccess<Series> | ResponseError>(`/api/v1/admin/series`, body)).data;
}

export async function updateSeries(body: SeriesBody): Promise<ResponseSuccess<Series> | ResponseError | any> {
    return (await client.put<ResponseSuccess<Series> | ResponseError>(`/api/v1/admin/series`, body)).data;
}

export async function deleteSeries(id: number): Promise<ResponseSuccess<Series> | ResponseError | any> {
    return (await client.delete<ResponseSuccess<Series> | ResponseError>(`/api/v1/admin/series/${id}`)).data;
}