import {client} from "../api";
import {Menu, MenuBody, MenuByID, MenuSetVisibleBody, ResponseError, ResponseSuccess} from "../models";


export async function getMenu(id: number): Promise<ResponseSuccess<MenuByID> | ResponseError | any> {
    return (await client.get<ResponseSuccess<MenuByID> | ResponseError>(`/api/v1/admin/menus/${id}`)).data;
}

export async function getMenus(page: number, pageSize: number): Promise<ResponseSuccess<Menu> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Menu> | ResponseError>(`/api/v1/guest/menus?page=${page}&size=${pageSize}`)).data;
}


export async function getFilterMenus(name: string, page: number, pageSize: number): Promise<ResponseSuccess<Menu> | ResponseError | any> {
    return (await client.get<ResponseSuccess<Menu> | ResponseError>(`/api/v1/admin/menus/browse?name=${name}&page=${page}&size=${pageSize}`)).data;
}


export async function createMenu(body: MenuBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.post<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/menus`, body)).data;
}

export async function setVisibleMenu(body: MenuSetVisibleBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.patch<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/menus/visibilities`, body)).data;
}


export async function updateMenu(body: MenuBody): Promise<ResponseSuccess<any> | ResponseError | any> {
    return (await client.put<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/menus`, body)).data;
}

export async function deleteMenu(id: number): Promise<ResponseSuccess<Menu> | ResponseError | any> {
    return (await client.delete<ResponseSuccess<any> | ResponseError>(`/api/v1/admin/menus/${id}`)).data;
}