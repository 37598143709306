import React, {FC} from 'react';
import {TextInput} from '../../components';
import {PropertyInputs} from './EditAddProperty';

interface Props {
    lang: string;
    langList: PropertyInputs;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PropertyInput: FC<Props> = ({lang, langList, onChange}) => {
    return (
        <div>
            <p className="property_input__label">{lang}</p>
            <ul className="property_input">
                <li>
                    <TextInput
                        placeholder='Name'
                        name='name'
                        onChange={onChange}
                        value={langList.name}
                    />
                </li>
                <li>
                    <TextInput
                        placeholder='Description'
                        name='description'
                        onChange={onChange}
                        value={langList.description}
                    />
                </li>
            </ul>
        </div>
    );
};

export default PropertyInput;