import React, {FC} from 'react';
import '../../../styles/components/UI/Button.scss';
import {useNavigate} from 'react-router-dom';

interface Props {
    isLoading: boolean;
    url: string;
}


const EditButton: FC<Props> = ({isLoading, url}) => {
    const navigate = useNavigate();

    const setNavigate = () => {
        if (!isLoading) navigate(url);
    }
    return (
        <button className='btn-edit' onClick={setNavigate}>
            {
                isLoading
                    ?
                    <p>Loading....</p>
                    :
                    <p>
                        Edit
                    </p>
            }
        </button>
    );
};

export default EditButton;