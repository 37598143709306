import {useMutation} from "@tanstack/react-query";
import {CreateProductTypeBody} from "../../models";
import {productTypeService} from "../../service";
import toast from "react-hot-toast";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateProductType = (refetch: any) => {

    return useMutation({
        mutationKey: ['create product type'],
        mutationFn: (body: CreateProductTypeBody) => productTypeService.createProductType(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateProductType = (refetch: any, refetchAvailable: any) => {

    return useMutation({
        mutationKey: ['update product type'],
        mutationFn: (body: CreateProductTypeBody) => productTypeService.updateProductType(body),
        onSuccess: async () => {
            await refetch();
            await refetchAvailable();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteProductType = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete product type'],
        mutationFn: (id: number) => productTypeService.deleteProductType(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

// const useSetPropertyToProductType = (refetch: any) => {

//     return useMutation({
//         mutationKey: ['update property value'],
//         mutationFn: (productTypeId: number, propertyId: number) => productTypeService.setPropertyToProductType(productTypeId, propertyId),
//         onSuccess: async () => {
//             await refetch();
//         },
//         onError: (axiosError: AxiosError) => {
//             handleErrorResponse(axiosError);
//         }
//     });
// }

// const useDeleteProductTypeProperty = (refetch: any) => {

//     return useMutation({
//         mutationKey: ['delete property value'],
//         mutationFn: (productTypeId: number, propertyId: number) => productTypeService.deleteProductTypeProperty(productTypeId, propertyId),
//         onSuccess: async () => {
//             await refetch();
//         },
//         onError: (axiosError: AxiosError) => {
//             handleErrorResponse(axiosError);
//         }
//     });
// }


export {
    useCreateProductType,
    useUpdateProductType,
    useDeleteProductType
}