import {useQuery} from "@tanstack/react-query";
import {Properties, Property, PropertyValue, ResponseSuccess} from "../../models";
import {propertyService} from "../../service";


const useGetProperties = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<Properties>>({
        queryFn: () => propertyService.getProperties(page, pageSize),
        queryKey: ['properties']
    })
}


const useGetProperty = (id: number) => {
    return useQuery<ResponseSuccess<Property>>({
        queryFn: () => propertyService.getPropertyByID(id),
        queryKey: ['property', id]
    })
}

const useGetPropertyValue = (id: number) => {
    return useQuery<ResponseSuccess<PropertyValue>>({
        queryFn: () => propertyService.getPropertyValueByID(id),
        queryKey: ['property-value', id]
    })
}

export {
    useGetProperties,
    useGetProperty,
    useGetPropertyValue
};