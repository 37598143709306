import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {BookTypes, BookType, ResponseSuccess} from "../../models";
import {bookTypeService} from "../../service";


const useGetBookTypes = (page: number, pageSize: number) => {
    return useQuery<ResponseSuccess<BookTypes>>({
        queryFn: () => bookTypeService.getBookTypes(page, pageSize),
        queryKey: ['book-types'],
        placeholderData: keepPreviousData
    })
}


const useGetBookType = (id: number) => {
    return useQuery<ResponseSuccess<BookType>>({
        queryFn: () => bookTypeService.getBookTypeByID(id),
        queryKey: ['book-type', id]
    })
}


export {
    useGetBookTypes,
    useGetBookType
};