import toast from 'react-hot-toast';
import {useMutation} from "@tanstack/react-query";
import {BookCoverTypeBody} from "../../models";
import {bookCoverTypeService} from "../../service";
import {AxiosError} from "axios";
import {handleErrorResponse} from "../../utils/errorResponseUtil";

const useCreateBookCoverType = (refetch: any) => {

    return useMutation({
        mutationKey: ['create book cover type'],
        mutationFn: (body: BookCoverTypeBody) => bookCoverTypeService.createBookCoverType(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Create was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}

const useUpdateBookCoverType = (refetch: any) => {

    return useMutation({
        mutationKey: ['update book cover type'],
        mutationFn: (body: BookCoverTypeBody) => bookCoverTypeService.updateBookCoverType(body),
        onSuccess: async () => {
            await refetch();
            toast.success('Update was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


const useDeleteBookCoverType = (refetch: any) => {

    return useMutation({
        mutationKey: ['delete book cover type'],
        mutationFn: (id: number) => bookCoverTypeService.deleteBookCoverType(id),
        onSuccess: async () => {
            await refetch();
            toast.success('Delete was successfully!');
        },
        onError: (axiosError: AxiosError) => {
            handleErrorResponse(axiosError);
        }
    });
}


export {
    useCreateBookCoverType,
    useUpdateBookCoverType,
    useDeleteBookCoverType
}